export const optimizedOnlySingledatafieldCard = {
  '647f76d5493c6d001363bede': ['Single datafield optimization'],
  '64c3e2a754da36001224ba0c': [
    'Single datafield optimization',
    'Factor exposure optimization',
    'Variance Minimization',
  ],
};

export const listThemes = [
  'Climate Impact',
  'Space',
  'Carbon Capture Solutions',
  'Green Energy and Energy Efficiency Solutions',
  'Smart and Green Infrastructure Solutions',
  'Green Transport Solutions',
  'Integrated/Diversified/Others Climate Impact',
  'Rocket and Launch',
  'Satellite Engineering',
  'Astronautical Engineering',
  'Space Equipment and Electronics',
  'Space Telecommunication',
  'Space Materials',
  'Space Operations and Services',
  'Integrated/Diversified/Others Space',
  'Air Pollution Control Systems',
  'Air Pollution Measurement Systems',
  'Air Purification Services',
  'Air Purification Systems',
  'Carbon Storage Services',
  'Carbon Storage Systems',
  'Carbon Transport Services',
  'Carbon Transport Systems',
  'Integrated and Diversified Carbon Capture and Storage',
  'Other Carbon Capture and Storage',
  'Emission Control Technologies',
  'Emission Reduction Equipment',
  'Integrated and Diversified Emission Control',
  'Other Emission Control',
  'Integrated and Diversified Carbon Capture Solutions',
  'Other Carbon Capture Solutions',
  'Biofuel Energy Production and Distribution',
  'Ethanol Production',
  'Biomass Production',
  'Integrated and Diversified Biofuel Energy',
  'Other Biofuel Energy',
  'Biomass Conversion Technologies',
  'Integrated and Diversified Biofuel Tech',
  'Other Biofuel Tech',
  'Wind Energy Production and Distribution',
  'Wind Turbine Manufacturing',
  'Integrated and Diversified Energy Efficiency Solutions',
  'Others Energy Efficiency Solutions',
  'Integrated and Diversified Wind Energy',
  'Other Wind Energy',
  'Wind Towers Manufacturing',
  'Wind Material Technologies',
  'Offshore Wind Farms',
  'Wind Power Storage',
  'Integrated and Diversified Wind Farms Technology',
  'Other Wind Farms Technology',
  'Geothermal Energy Production and Distribution',
  'Integrated and Diversified Geothermal Energy',
  'Other Geothermal Energy',
  'Hydroelectric Energy Production and Distribution',
  'Integrated and Diversified Hydroelectric Energy',
  'Other Hydroelectric Energy',
  'Hydrogen Production',
  'Hydrogen Fuel Cell Energy Production',
  'Hydrogen Fuel Cell Manufacturing',
  'Integrated and Diversified Hydrogen Energy',
  'Other Hydrogen Energy',
  'Hydrogen Energy Technology Systems Manufacturing',
  'Integrated and Diversified Hydrogen Technologies',
  'Other Hydrogen Technologies',
  'Solar Energy Production and Distribution',
  'Integrated and Diversified Solar Energy',
  'Other Solar Energy',
  'Solar Power Products Manufacturing',
  'Solar Cells Manufacturing',
  'Integrated and Diversified Solar Power Systems',
  'Other Solar Power Systems',
  'Nuclear Energy Generation and Distribution',
  'Integrated and Diversified Nuclear Energy',
  'Other Nuclear Energy',
  'Integrated and Diversified Green Energy',
  'Other Green Energy',
  'Lithium Batteries System Manufacturing',
  'Non-Lithium Batteries System Manufacturing',
  'Battery Energy Storage Systems Construction',
  'Thermal Storage',
  'Energy Efficient UPS Systems',
  'Integrated and Diversified Energy Storage Tech',
  'Other Energy Storage Tech',
  'Hydroelectric Technologies',
  'Renewable Fuels Production',
  'Sustainable Energy Distribution Operation',
  'Integrated and Diversified Green Energy and Energy Efficiency Solutions',
  'Other Green Energy and Energy Efficiency Solutions',
  'Wind Power Farms Construction and Design',
  'Biomass Energy Plants Construction',
  'Hydrogen Power Plant Construction',
  'Nuclear Plant Construction and Engineering',
  'Geothermal Power Plant Construction',
  'Hydroelectric Energy Infrastructure',
  'Solar Power Plants Construction and Design',
  'Solar Energy Infrastructure',
  'Green Construction Materials',
  'Green Building Constructions',
  'Energy Efficient HVAC Solutions',
  'Intelligent Transportation System Services',
  'Smart Building Construction',
  'Smart Building Equipment',
  'Smart Building Systems',
  'Smart Energy Monitoring and Management Software',
  'Smart Energy Monitoring and Management Systems',
  'Smart Grids Design and Development',
  'Smart Grids Equipment Design and Development',
  'Smart Grids Equipment Manufacturing and Marketing',
  'Smart Lighting Equipment Design and Development',
  'Smart Lighting Systems Manufacturing and Marketing',
  'Integrated and Diversified Smart Electricity Grids',
  'Other Smart Electricity Grids',
  'Solar-factor Control Glass and PV Glass',
  'Wastewater Treatment Plant Construction',
  'Water Treatment Plant Construction',
  'Sustainable Water Systems',
  'Sustainable Energy Distribution Infrastructure Construction',
  'Integrated and Diversified Smart and Green Infrastructure Solutions',
  'Others Smart and Green Infrastructure Solutions',
  'Autonomous Driving (AD) Services',
  'Autonomous Trains Design and Development',
  'Autonomous Trains Manufacturing and Marketing',
  'Autonomous Vehicles Design and Development',
  'Autonomous Vehicles Manufacturing and Marketing',
  'Electric and Hybrid Vehicles Rental',
  'Electric Powerboats and Boats Design and Development',
  'Electric Powerboats and Boats Manufacturing and Marketing',
  'Electric Powerboats and Boats Rental',
  'Electric Trains Design and Development',
  'Electric Trains Manufacturing and Marketing',
  'EV Design and Development',
  'EV Manufacturing and Marketing',
  'Smart Bicycles Design and Development',
  'Smart Bicycles Manufacturing and Marketing',
  'Smart Bicycles Rental',
  'Solar Trains Design and Development',
  'Solar Trains Manufacturing and Marketing',
  'Smart Traffic Control System Services',
  'Geospatial Location Technology Services',
  'Traffic Mobile Applications Services',
  'Integrated and Diversified Smart Routes Technology',
  'Other Smart Routes Technology',
  'HEV Design and Development',
  'HEV Manufacturing and Marketing',
  'EV, HEV and PHEV Charging Infrastructure Design and Development',
  'EV, HEV and PHEV Charging Infrastructure Manufacturing and Marketing',
  'PHEV Design and Development',
  'PHEV Manufacturing and Marketing',
  'Lithium Batteries Design and Development',
  'Lithium Batteries Manufacturing and Marketing',
  'EV, HEV and PHEV Connectors and others Components Design and Development',
  'EV, HEV and PHEV Connectors and others Components Manufacturing and Marketing',
  'Integrated and Diversified EV, HEV and PHEV Supplies',
  'Other EV, HEV and PHEV Supplies',
  'Ride-Sharing Platform Services',
  'Car-Sharing Platform Services',
  'Micro-Mobility Platform Services',
  'Integrated and Diversified Mobility Sharing Systems',
  'Other Mobility Sharing Systems',
  'Electric Motorcycles Design and Development',
  'Electric Motorcycles Manufacturing and Marketing',
  'Integrated and Diversified Electric Motorcycles Production',
  'Other Electric Motorcycles Production',
  'Electric Bicycles Design and Development',
  'Electric Bicycles Manufacturing and Marketing',
  'Integrated and Diversified Electric Bicycles Production',
  'Other Electric Bicycles Production',
  'Bicycle Manufacturing',
  'Sustainable Freight and Logistics Services',
  'Electrical Vertical Take-off and Landing Aircrafts',
  'Rail Transport Infrastructure',
  'Road and Public Transport Infrastructure',
  'Bicycle Infrastructure',
  'Integrated and Diversified Green Transport Solutions',
  'Others Green Transport Solutions',
  'Integrated and Diversified Climate Impact',
  'Other Climate Impact',
  'Rockets and OLV Manufacturing',
  'Rocket Components Manufacturing',
  'Propulsion Systems Manufacturing',
  'Orbital Launch Operations',
  'Rocket Propellants and Fuel',
  'Integrated and Diversified Rocket and Launch',
  'Other Rocket and Launch',
  'Satellite Manufacturing',
  'Satellite Components Manufacturing',
  'Integrated and Diversified Satellite Engineering',
  'Other Satellite Engineering',
  'Astronautical Systems Manufacturing',
  'Astronautical Design and Consulting',
  'Integrated and Diversified Astronautical Engineering',
  'Other Astronautical Engineering',
  'Space Flight Hardware',
  'Integrated and Diversified Space Equipment and Electronics',
  'Other Space Equipment and Electronics',
  'Satellite Networks',
  'Satellite Imagery and Telemetry',
  'Optical Space Communication Systems',
  'Integrated and Diversified Space Telecommunication',
  'Other Space Telecommunication',
  'Space Materials Technology',
  'Commercial Space Travel',
  'Satellite Operations and Maintenance',
  'Integrated and Diversified Space Operations and Services',
  'Other Space Operations and Services',
  'Integrated and Diversified Space',
  'Other Space',
];
