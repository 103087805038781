import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../builder.module.scss';
import { getDataComponents } from '../../../api-data-mapping';
import Switcher from '../../../bitaComponents/Switcher/Switcher';
import BitaCheckbox from '../../../components/BitaCheckbox/BitaCheckbox';
import { setApiData } from '../../../state/actions/api-data-actions';
import { loaderDataObjectDecorator } from '../../../utils/loading_status_tools';

import { ComponentGenerator } from '../Components';

export const BuilderToggleOnlyOption = ({
  setterFunction = Function.prototype,
  setterFilter = Function.prototype,
  filterField = '',
  onChange = Function.prototype,
  path,
  options = [],
  id = '',
  widgetData,
  subcomponents_direction,
  disabled = false,
  default_option = false,
  historyAddibleData,
  styling,
  hidden,
}) => {
  const dispatch = useDispatch();
  const universeSelected = useSelector(state => state.summary);
  const auth = useSelector(state => state.auth);
  const [selected, setSelected] = useState(
    default_option ? Object.values(options).filter(i => i.title === default_option)[0] : '',
  );
  let idUniverse = false;
  if (
    universeSelected &&
    universeSelected.universeBuilderOperations &&
    universeSelected.universeBuilderOperations.universe.id
  ) {
    idUniverse = universeSelected.universeBuilderOperations.universe.id;
  }
  React.useEffect(() => {
    /**
     * The next condition is to check default toggle
     */
    if (typeof options[0] === 'object') {
      Object.values(options).forEach(option => option.toggled_by_default && setSelected(option));
    }
    if (widgetData && widgetData[id]) {
      if (typeof options[0] === 'object') {
        const searchWidget = () => {
          if (
            widgetData[id] === 'Perform Extraordinary Reconstitution' ||
            widgetData[id] === 'Same Weight'
          ) {
            return 'Simultaneous to Event';
          }
          return widgetData[id];
        };
        const dataSelected = Object.values(options).filter(
          option => option.title === searchWidget(),
        );

        setSelected(dataSelected[0]);
      } else {
        setSelected(widgetData[id]);
      }
    }
  }, []);

  const localPath = path ? `${path}.${id}` : id;
  React.useEffect(() => {
    if (setterFilter && setterFunction) {
      if (selected && selected.length > 0)
        setterFunction(prev => ({ ...prev, RadioGroup: selected }));
      if (setterFilter) setterFilter(prev => ({ field: filterField, filter: selected }));
    }
    if (onChange && selected)
      onChange(selected.id ? `${localPath}.${selected.id}` : localPath, selected.title);
    return () => {
      if (selected && selected.onclick && typeof selected.onclick[0] === 'object') {
        Object.values(selected.onclick).forEach(comp => {
          if (comp.id) {
            onChange(`${path}.${comp.id}`, undefined);
          }
        });
      } else if (selected && selected.onclick && selected.onclick.id) {
        onChange(`${path}.${selected.onclick.id}`, undefined);
      }
    };
  }, [selected]);

  React.useEffect(() => {
    if (selected.title === 'No Apply Cumulative Cut-off') {
      (async function() {
        let res = {};
        const dataComponent = getDataComponents.filter(
          v => v.key === 'Geography Dropdown' && v.title === 'Classification',
        )[0];

        if (historyAddibleData?.classification?.value === 'BITA Assigned Country') {
          res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
            '3',
            idUniverse,
            auth.token,
          );
          dispatch(
            setApiData({
              title: 'Geography Dropdown_BITA Assigned Country',
              data: dataComponent.formatData(res.data || []),
            }),
          );
        } else if (historyAddibleData?.classification?.value === 'Country of Primary Listing') {
          res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
            '0',
            idUniverse,
            auth.token,
          );
          dispatch(
            setApiData({
              title: 'Geography Dropdown_Country of Primary Listing',
              data: dataComponent.formatData(res.data || []),
            }),
          );
        } else if (historyAddibleData?.classification?.value === 'Country of Incorporation') {
          res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
            '1',
            idUniverse,
            auth.token,
          );
          dispatch(
            setApiData({
              title: 'Geography Dropdown_Country of Incorporation',
              data: dataComponent.formatData(res.data || []),
            }),
          );
        } else if (historyAddibleData?.classification?.value === 'Country of Headquarters') {
          res = await loaderDataObjectDecorator(dataComponent, dispatch).getUrlData(
            '2',
            idUniverse,
            auth.token,
          );
          dispatch(
            setApiData({
              title: 'Geography Dropdown_Country of Headquarters',
              data: dataComponent.formatData(res.data || []),
            }),
          );
        }
      })();
    }
  }, [selected]);

  if (typeof options[0] === 'object') {
    return (
      <div
        className={`${styles.radioParentGroup} builderToggleGroup`}
        style={{
          opacity: disabled ? (hidden ? '0' : '0.5') : '1',
          pointerEvents: disabled ? 'none' : '',
          flexFlow: subcomponents_direction === 'horizontal' ? 'row' : 'column',
        }}
      >
        {Object.values(options).map((option, key) => (
          <div key={option.title}>
            <div
              className={`${styles.radioHolder} radioHolder `}
              style={
                subcomponents_direction === 'horizontal'
                  ? {
                      minWidth: '100%',
                      width: '100%',
                      marginRight: 30,
                      opacity: option.disabled ? '0.5' : '1',
                      pointerEvents: option.disabled ? 'none' : '',
                      display: key === 1 ? 'none' : '',
                    }
                  : {
                      minWidth: '100%',
                      width: '100%',
                      opacity: option.disabled ? '0.5' : '1',
                      pointerEvents: option.disabled ? 'none' : '',
                      display: key === 1 ? 'none' : '',
                    }
              }
            >
              <BitaCheckbox
                label={option.title}
                checked={
                  id === 'value_default_nro_constituents'
                    ? !(option === selected)
                    : option === selected
                }
                handleChange={checked => {
                  // eslint-disable-next-line no-unused-expressions
                  id === 'value_default_nro_constituents'
                    ? onChange(localPath, { value: option.title, id: option.title }, '')
                    : onChange(path, {});
                  if (checked) {
                    setSelected(option);
                  } else {
                    setSelected(
                      key === 0 ? Object.values(options)?.[1] : Object.values(options)?.[0],
                    );
                    // eslint-disable-next-line no-unused-expressions
                    id === 'value_default_nro_constituents'
                      ? onChange(localPath, false, '')
                      : onChange(
                          localPath,
                          key === 0
                            ? {
                                value: Object.values(options)?.[1].title,
                                id: Object.values(options)?.[1].title,
                              }
                            : {
                                value: Object.values(options)?.[0].title,
                                id: Object.values(options)?.[0].title,
                              },
                          '',
                        );
                  }
                }}
              />
            </div>
            {selected && option.title === selected.title && option.onclick && (
              <div
                className={`${
                  styling === 'structured' ? styles.structuredWidget : styles.builderRadioGroup
                } builderRadioGroup `}
                style={option.options_text ? { flexDirection: 'column' } : {}}
              >
                {option.options_text && <span>{option.options_text}</span>}
                {typeof option.onclick[0] === 'object' ? (
                  Object.values(option.onclick).map((comp, index) => (
                    <ComponentGenerator
                      {...comp}
                      path={path}
                      onChange={onChange}
                      widgetData={widgetData}
                      key={index + comp.toString()}
                      historyAddibleData={historyAddibleData}
                    />
                  ))
                ) : (
                  <ComponentGenerator
                    {...option.onclick}
                    path={path}
                    onChange={onChange}
                    widgetData={widgetData}
                    historyAddibleData={historyAddibleData}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  return Object.values(options).map(option => (
    <div key={option}>
      <div className={`${styles.radioHolder} radioHolder `}>
        <span>{option}</span>
        <Switcher.Switch
          key={option}
          className={styles.customRadio}
          onChange={() => (option === selected ? setSelected('') : setSelected(option))}
          checked={option === selected}
        />
      </div>
    </div>
  ));
};
