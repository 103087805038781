import React from 'react';
import style from '../PortfolioBasketCalculation.module.scss';

const RequestCoverageTable = ({ data }) => {
  // Helper function to render table headers conditionally
  const renderHeaders = () => {
    return (
      <>
        <div>ISIN</div>
        <div>MIC</div>
        {data?.meta_reference_data?.ticker && <div>Ticker</div>}
        {data?.meta_reference_data?.company_name && <div>Company Name</div>}
        {data?.meta_reference_data?.sedol && <div>SEDOL</div>}
      </>
    );
  };
  console.info(data?.request_coverage);
  // Helper function to render table rows
  const renderRows = () => {
    return (data?.request_coverage ?? []).map(item => {
      console.info(item);
      const key = Object.keys(item)[0];
      const value = Object.values(item)[0];
      return (
        <div className={style.requestCoverageEntry} key={key}>
          <div>{key}</div>
          <div>{value}</div>
          {data?.meta_reference_data?.ticker && <div>{data.meta_reference_data.ticker[key]}</div>}
          {data?.meta_reference_data?.company_name && (
            <div>{data.meta_reference_data.company_name[key]}</div>
          )}
          {data?.meta_reference_data?.sedol && <div>{data.meta_reference_data.sedol[key]}</div>}
        </div>
      );
    });
  };

  return (
    <div className={style.tableNoMetadata}>
      <div className={style.requestCoverageEntry}>{renderHeaders()}</div>
      {renderRows()}
    </div>
  );
};

export default RequestCoverageTable;
