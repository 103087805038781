import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'simplebar';
import { useCSVReader } from 'react-papaparse';
import { Progress } from 'react-sweet-progress';
import Papa from 'papaparse';

import 'simplebar/dist/simplebar.css';
import FileSaver from 'file-saver';
import { FaCheckSquare } from 'react-icons/fa';
import { BiSolidError, BiX, BiCheck } from 'react-icons/bi';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';
import DragAndDropFile from '../../components/DragAndDropFiles/DragAndDropFile';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import SaveSuccessBasketModal from './Modals/SaveSuccessBasketModal';
import { toastInterceptor } from '../../utils/utils';
import { BULK_BASKET_DATA } from './utils/dataDummie';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';

import { DATA_LIST_DROPDOWN, BASKET_BUTTON_BULK } from './utils/constants';
import { createBasket, uploadFileSettings } from './utils/api';
import { BulkPrevieBaskets } from './Components/BulkComponents';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import styles from './index-builder.module.css';
import style from './PortfolioBasketCalculation.module.scss';
import BitaSearchBar from '../../bitaComponents/BitaSearchBar/BitaSearchBar';
import './Portfolio.css';

const BASKETS_TABS_DATA = {
  BDELUT: {},
  BDELUN: {},
  BDELUP: {},
  BDELET: {},
};

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const BulkBasketSubmission = () => {
  // const ListBasket = Object.keys(BASKETS_TABS_DATA).map(key => {
  //   return { label: key, value: key };
  // });
  const token = useSelector(state => state.auth.token);
  const authOrgPrefix = useSelector(state => state.auth?.org_prefix);
  const [type, setType] = useState('Long only Basket');
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalConfirmBacktest, setShowModalConfirmBacktest] = useState(false);
  const [showModalBasketInProgress, setShowModalBasketInProgress] = useState(false);
  const [showBasketsTabs, setShowBasketsTabs] = useState(false);
  const [showMessageRepeat, setShowMessageRepeat] = useState(false);

  const [dataArray, setDataArray] = useState([]);
  const [dataArrayRepeat, setDataArrayRepeat] = useState([]);
  const [dataSendBasket, setDataSendBasket] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  const { CSVReader } = useCSVReader();
  const [constituentsDelete, setConstituentsDelete] = useState([]);
  const [files, setFiles] = React.useState('');
  const [filesBacktest, setFilesBacktest] = React.useState('');
  const [dataBacktest, setDataBacktest] = useState([]);
  const [showModalRequestCoverage, setShowModalRequestCoverage] = useState(false);
  const [selectOptionPreview, setSelectOptionPreview] = useState();
  const [orgPrefix, setOrgPrefix] = useState(authOrgPrefix || '');

  const onDrop = useCallback(acceptedFiles => {
    const allowedFiles = acceptedFiles.filter(
      file =>
        file.type === 'text/csv' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    setListFiles(allowedFiles);
  }, []);

  useEffect(() => {
    // console.info(data); //for single data
    // console.info(dataArray); //for bulk data
  }, [data, dataArray]);

  const ListBasket = useMemo(() => {
    return (dataArray.length > 0 ? dataArray : data?.metadata ? [data] : []).map(item => ({
      label: item?.symbol ?? 'label',
      // value: item?.id ?? item?.name,
      value: item?.symbol,
    }));
  }, [dataArray, data]);

  const filesList = () => {
    if (listFiles?.length > 3) {
      return (
        <div style={{ overflowWrap: 'break-word' }}>
          {listFiles
            .slice(0, 3)
            .map(item => `${item?.name?.replace('.csv', '').replace('.excel', '')}, `)}{' '}
          and {listFiles.length - 3} more ...
        </div>
      );
    }
    return <div>{listFiles.map(item => `${item.name}, `)}</div>;
  };
  const BacktestTable = () => {
    const renderTable = () => {
      const columns = Object.keys(data?.dataBacktest?.[0]);
      const rows = data?.dataBacktest.slice(1);

      return (
        <>
          <div style={{ border: 'none', marginBottom: '0px' }}>Backtest Preview</div>
          <BitaTable
            data={rows}
            search={false}
            showFields={columns}
            height="300px"
            showOnly={10}
            pagination
            selectAction={() => {}}
            CustomRowComponent={({ value }) => (
              <div>
                <p style={{ paddingRight: '20px' }}>{value}</p>
              </div>
            )}
          />
        </>
      );
    };
    return <div className={style.contentWidget}>{renderTable()}</div>;
  };

  // eslint-disable-next-line no-shadow
  const updateAndFormatData = (data, shouldShowToast) => {
    if (data?.metadata?.divisor && data?.starting_value) {
      const { starting_value: _, ...updatedData } = data;
      setData({ ...updatedData });
      if (shouldShowToast) toast('Your file has been processed successfully');
    } else if (!data?.metadata?.divisor && !data?.starting_value) {
      toast.error(
        'Error: Invalid input file. Please make sure you have "divisor" or "starting_value" field and try again.',
      );
    } else {
      setData(data);
      if (shouldShowToast) toast('Your file has been processed successfully');
    }
  };

  useEffect(() => {
    console.info(showMessageRepeat, dataArrayRepeat);
    if (showMessageRepeat && dataArrayRepeat.length > 0) {
      const updateData = [...dataArray].filter(item => !dataArrayRepeat.includes(item.symbol));
      const showDataRepeat = [...new Set(dataArrayRepeat)];
      setDataArray(updateData);
      if (updateData.length > 0) {
        updateAndFormatData(updateData[0], true);
        setSelectOptionPreview({ label: updateData[0]?.symbol, value: updateData[0]?.symbol });
      }
      setShowMessageRepeat(false);
      toast.error(
        `The following symbol(s) are repeated in one or more of the uploaded files: ${showDataRepeat.join(
          ', ',
        )}`,
      );
    }
    if (showMessageRepeat && dataArrayRepeat.length === 0) {
      updateAndFormatData(dataArray[0], true);
      setSelectOptionPreview({ label: dataArray[0]?.symbol, value: dataArray[0]?.symbol });
      setShowMessageRepeat(false);
    }
  }, [showMessageRepeat]);

  const handleFileParse = (listBasket, basketSelect) => {
    Papa.parse(filesBacktest, {
      complete: results => {
        if (
          results.data.length > 1 &&
          results.data[0][0].toUpperCase() === 'DATE' &&
          results.data[0][1].toUpperCase() === 'VALUE'
        ) {
          setDataBacktest(results.data);
          basketSelect.dataBacktest = results.data;
          basketSelect.fileBacktest = filesBacktest;
          const updatedArray = listBasket.map(item =>
            item.symbol === selectOptionPreview.value ? basketSelect : item,
          );
          setDataArray(updatedArray);
          setData(basketSelect);
          toast(`Uploaded backtest is linked to ${basketSelect.symbol} Basket`);
        } else {
          toast.error(
            'Invalid file type structure. Please upload a file that matches the required format',
          );
        }
      },
      header: false,
    });
  };

  const sendBasket = async dataBasket => {
    const formData = new FormData();
    const updatedData = {
      ...dataBasket,
      deployment_setup:
        data?.calculation_setup !== 'EOD_INTRADAY' || dataBasket.deployment_setup === 'EOD'
          ? 'PREVIOUS_EOD'
          : dataBasket.deployment_setup,
      metadata: { ...dataBasket?.metadata, divisor: dataBasket?.divisor },
      launch_date: dataBasket?.launch_date,
      type: 'basket',
      // type: type.includes('Bottom') ? 'global' : type.toLowerCase().replace('/', '_'),
    }; // Add the 'type' property to the data object

    // delete updatedData.cash_distribution; // Remove the "cash_distribution" key
    delete updatedData.region;
    delete updatedData.request_coverage;
    delete updatedData.full_coverage;
    delete updatedData.org_prefix;
    delete updatedData.dataBacktest;
    delete updatedData.fileBacktest;
    if (dataBasket?.calculation_setup !== 'EOD_INTRADAY') {
      updatedData.calculation_setup = 'EOD';
    }
    if (dataBasket?.cash_constituent === null) {
      delete dataBasket?.cash_constituent;
    }
    const jsonString = JSON.stringify(updatedData);

    formData.append('data', jsonString);
    formData.append('org_prefix', orgPrefix);

    if (dataBasket.fileBacktest) {
      formData.append('template', dataBasket.fileBacktest);
    }

    try {
      const rsp = await createBasket(token, formData);
      setDataSendBasket(prev => [...prev, { symbol: dataBasket.symbol, send: 'success' }]);
    } catch (error) {
      toastInterceptor(error);
      setDataSendBasket(prev => [...prev, { symbol: dataBasket.symbol, send: 'fail' }]);
    }
  };

  const processBaskets = async () => {
    setShowModalBasketInProgress(true);
    const batchSize = 1;

    const processBatch = async batch => {
      await Promise.all(batch.map(dataBasket => sendBasket(dataBasket)));
    };

    const processAllBatches = async () => {
      await dataArray.reduce(async (previousPromise, _, index) => {
        await previousPromise;

        const batch = dataArray.slice(index, index + batchSize);
        await processBatch(batch);

        await new Promise(resolve => setTimeout(resolve, 1000));

        return Promise.resolve();
      }, Promise.resolve());
    };

    try {
      await processAllBatches();
    } catch (error) {
      console.error('Error processing baskets:', error);
    }
  };

  const cleanState = () => {
    setShowModalBasketInProgress(false);
    setDataArray([]);
    setDataArrayRepeat([]);
    setDataSendBasket([]);
    setData({});
    setListFiles([]);
  };

  const BasketTable = ({
    titleBasketTable,
    deleteBasketTable,
    buttonsBasketTable,
    basketsData,
    noScroll,
  }) => {
    const renderTable = () => {
      if (data.metadata) {
        const columns = Object.keys(data.metadata).filter(column => column !== 'exchange');
        columns.unshift('constituents');
        if (Object.keys(data.metadata).find(column => column === 'exchange')) {
          columns.unshift('MIC');
        }

        const rows = Object.keys(data.metadata.awf).map((constituent, index) => {
          const rowData = {
            constituents: constituent,
          };

          Object.keys(data.metadata).forEach(key => {
            rowData[key] = key === 'divisor' ? data.metadata[key] : data.metadata[key][constituent];
          });
          if (rowData.exchange) {
            rowData.MIC = rowData.exchange;
          }

          // if (constituentsError.includes(constituent)) {

          if (data?.request_coverage?.some(item => constituent in item)) {
            rowData.info = 'error';
          } else {
            rowData.info = 'success';
          }
          return rowData;
        });
        return (
          <div className="contentTableBasketPreview">
            {titleBasketTable && (
              <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
            )}
            {titleBasketTable && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1>General file information section</h1>
              </div>
            )}
            <BitaTable
              data={rows}
              height="100%"
              style={{ padding: '0' }}
              search={false}
              scroll={!noScroll}
              showFields={deleteBasketTable ? [...columns, 'info'] : columns}
              selectAction={() => {}}
              editButton={false}
              CustomRowComponent={({ dataValue, entryKey, value }) => (
                <div className="custom-row-container">
                  <p className={`${entryKey === 'info' ? 'info-icon-live' : ''}`}>
                    {entryKey === 'info' && deleteBasketTable ? (
                      value === 'error' ? (
                        <BiSolidError className="icon-error" />
                      ) : (
                        <FaCheckSquare className="icon-info" />
                      )
                    ) : entryKey === 'constituents' && deleteBasketTable ? (
                      <div style={{ display: 'flex' }}>
                        <BitaCheckbox
                          checked={constituentsDelete.includes(value)}
                          handleChange={checked => {
                            if (checked) {
                              setConstituentsDelete([...constituentsDelete, value]);
                            } else {
                              setConstituentsDelete(
                                constituentsDelete.filter(item => item !== value),
                              );
                            }
                          }}
                        />
                        {value}
                      </div>
                    ) : (
                      value
                    )}
                  </p>
                  {entryKey === 'info' && value === 'error' && (
                    <div className="tooltip error-tooltip">
                      <div>
                        <BiX className="tooltip-icon icon-error" />
                      </div>
                      <span>
                        This instrument is not under the BITA Calculation platform pricing coverage.
                        You can request the coverage using the button below &quot; Request Coverage
                        &quot;. We will receive the request, process it, and communicate with you
                        via e-mail as soon as possible.
                      </span>
                    </div>
                  )}
                  {entryKey === 'info' && value === 'success' && (
                    <div className="tooltip success-tooltip">
                      <div>
                        <BiCheck className="tooltip-icon icon-info" />
                      </div>
                      <span>The input has been successfully validated</span>
                    </div>
                  )}
                </div>
              )}
            />

            {data.request_coverage && buttonsBasketTable && (
              <BitaButton
                disabled
                primary
                width="160px"
                style={{ marginRight: 'auto', marginTop: '60px' }}
                height="35px"
                onClick={() => setShowModalRequestCoverage(true)}
              >
                Request Coverage
              </BitaButton>
            )}

            {buttonsBasketTable && (
              <BitaButton
                disabled={constituentsDelete.length === 0}
                primary
                width="160px"
                style={{ marginLeft: '20px', marginTop: '60px' }}
                height="35px"
                onClick={() => {
                  setShowModalDelete(true);
                }}
              >
                Delete Constituent
              </BitaButton>
            )}
          </div>
        );
      }

      // Hide this for now
      // if (type === 'Bottom-Up Basket' && data.children_symbols) {
      //   const rows = data.children_symbols.map(symbol => ({
      //     children_symbols: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>
      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['children_symbols']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      // Hide this for now
      // if (type === 'Long/Short' && data.global_baskets) {
      //   const rows = Object.values(data.global_baskets).map(symbol => ({
      //     basket_symbol: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>

      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['basket_symbol']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      if (type === 'Decrement Index') {
        return <></>;
      }
      return null;
    };

    return <div className={!titleBasketTable ? '' : style.contentWidget}>{renderTable()}</div>;
  };

  return (
    <div>
      <div className={styles.compositionPorfolio}>
        <div className={styles.compositionPorfolioTitle}>Basket Composition/Portfolio</div>
        <div className={style.contentDataUpload}>
          <div className={style.dataUpload}>
            <div>
              <div className={style.subtitle}>BASKET TYPE</div>
              <div style={{ marginTop: '10px' }}>
                <BitaSelect
                  warningIcon
                  selected={type}
                  value={{ value: type, id: type, name: type }}
                  label="level"
                  data={DATA_LIST_DROPDOWN}
                  handleSelection={value => setType(value.value)}
                  style={{ fontSize: 13 }}
                />
              </div>
            </div>
            <div className={style.contentTemplate}>
              <div className={style.subtitle}>TEMPLATES</div>
              <div style={{ display: 'flex', gap: '20px', marginTop: '10px', flexWrap: 'wrap' }}>
                {Object.values(BASKET_BUTTON_BULK[type] ?? {}).map(button => (
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/${button?.url}.csv`,
                        `${button?.url}.csv`,
                      );
                    }}
                  >
                    {button?.label}
                  </BitaButton>
                ))}
              </div>
            </div>
            <div>
              <div className={style.subtitle}>Portfolio Uploader</div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  alignItems: 'center',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <input
                  type="file"
                  onChange={event => {
                    const newFiles = event.target.files;
                    if (newFiles instanceof FileList) {
                      delete newFiles.length;
                    }
                    onDrop(Object.values(newFiles));
                  }}
                  name="file"
                  id="file"
                  multiple
                  className={style.inputfile}
                />
                <label htmlFor="file">Select File</label>
                <BitaButton
                  disabled={!(listFiles && listFiles.length > 0)}
                  onClick={() => {
                    setDataArray([]);
                    setDataArrayRepeat([]);
                    setData({});

                    if (!listFiles || listFiles.length === 0) return;

                    const uploadPromises = listFiles.map((file, index) => {
                      const formData = new FormData();
                      formData.append('template', file);

                      if (type === 'Long only Basket') {
                        formData.append('type', 'basket');
                      } else {
                        formData.append('type', type.replace('/', '_'));
                      }

                      return uploadFileSettings(token, formData)
                        .then(rsp => {
                          if (rsp.data.data.symbol) {
                            setDataArray(prevDataArray => {
                              if (
                                prevDataArray.find(item => item.symbol === rsp?.data?.data?.symbol)
                                  ?.symbol
                              ) {
                                setDataArrayRepeat(prev => [...prev, rsp?.data?.data?.symbol]);
                              }
                              const updatedArray = [
                                { ...rsp.data.data, id: index },
                                ...(prevDataArray ?? []),
                              ];
                              return updatedArray;
                            });
                            setShowBasketsTabs(true);
                          } else {
                            toast.error(
                              `The file ${file?.name} could not be loaded because it does not have a Symbol`,
                            );
                          }
                        })
                        .catch(error => {
                          toastInterceptor(error);
                        });
                    });

                    Promise.all(uploadPromises)
                      .then(() => {
                        // setDataArrayRepeat([]);
                        setShowMessageRepeat(true);
                      })
                      .catch(error => {
                        console.error('Error en alguna de las llamadas a la API:', error);
                      });
                  }}
                  primary
                  width="160px"
                  height="35px"
                >
                  Upload File
                </BitaButton>
                <div style={{ width: '100%', maxWidth: '360px' }}>
                  <DragAndDropFile onChange={onDrop} />
                  <div style={{ fontSize: '16px', marginTop: '10px', color: '#a7a7aa' }}>
                    {listFiles && listFiles?.length > 0 && filesList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: '0px 20px' }}>
            {data?.metadata && (
              <div
                className="contentTableBasketPreview"
                style={{ marginTop: '0px', paddingTop: '0px' }}
              >
                <div className="headerTable">
                  <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>General file information section</h1>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    <div style={{ marginBottom: '10px', paddingRight: '20px' }}>
                      SELECTED BASKET
                    </div>
                    <div style={{ width: '200px' }}>
                      <BitaSearchBar
                        searchOptions={ListBasket}
                        style={{ with: '200px', float: 'right' }}
                        value={selectOptionPreview?.label}
                        onChange={value => {
                          setSelectOptionPreview(value);
                          if (dataArray.length > 0) {
                            updateAndFormatData(
                              dataArray.find(item => item.symbol === value?.value),
                              false,
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {data && (
                  <div data-simplebar style={{ height: '360px' }}>
                    <div>
                      <BasketTable
                        deleteBasketTable
                        ListBasket={ListBasket}
                        basketsData={BULK_BASKET_DATA}
                        noScroll
                      />
                    </div>
                  </div>
                )}
                {data && data.request_coverage && true && (
                  <BitaButton
                    disabled={!Object.keys(data.request_coverage).length > 0}
                    primary
                    width="160px"
                    style={{ marginRight: 'auto', marginTop: '20px' }}
                    height="35px"
                    onClick={() => setShowModalRequestCoverage(true)}
                  >
                    Request Coverage
                  </BitaButton>
                )}

                {data && true && (
                  <BitaButton
                    disabled={constituentsDelete.length === 0}
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Delete Constituent
                  </BitaButton>
                )}
              </div>
            )}
          </div>
          {/* <div className="content-tabs-bulksbasket">
            {data?.metadata && (
              <BasketTable
                titleBasketTable
                deleteBasketTable
                buttonsBasketTable
                ListBasket={ListBasket}
                basketsData={BULK_BASKET_DATA}
              />
            )}

            {/*  {showBasketsTabs && (
              <BulkPrevieBaskets ListBasket={ListBasket} basketsData={BULK_BASKET_DATA} />
            )} 
          </div> */}
        </div>
      </div>

      {/* Remove this for now */}
      <div className={styles.compositionPorfolio}>
        <div className={styles.compositionPorfolioTitle}>Backtest</div>
        <div className={style.contentDataUpload}>
          <div className={style.dataUpload}>
            <div>
              <div>
                <div className={style.subtitle}>TEMPLATES</div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginTop: '10px',
                    marginBottom: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/backtest_template.csv`,
                        `backtest_template.csv`,
                      );
                    }}
                  >
                    Backtest Template
                  </BitaButton>
                </div>
              </div>
              <div className={style.subtitle}>Backtest Uploader</div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  pointerEvents: files?.name || listFiles?.length > 0 ? 'auto' : 'none',
                  opacity: files?.name || listFiles?.length > 0 ? 1 : 0.5,
                }}
              >
                <div style={{ marginBottom: '10px' }}>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={event => {
                      const file = event.target.files[0];
                      setFilesBacktest(file);
                      // setDataBacktest({});
                    }}
                    style={{ display: 'none' }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className={styles.buttonUpload}>
                    Select File
                  </label>
                  <div style={styles.acceptedFile} />
                </div>
                <BitaButton
                  disabled={!filesBacktest?.name}
                  width="160px"
                  onClick={() => {
                    const listBasket = [...dataArray];
                    const basketSelect = {
                      ...listBasket.find(item => item.symbol === selectOptionPreview.value),
                    };
                    if (basketSelect?.dataBacktest?.length > 2) {
                      setShowModalConfirmBacktest(true);
                    } else {
                      handleFileParse(listBasket, basketSelect);
                      // Papa.parse(filesBacktest, {
                      //   complete: results => {
                      //     if (
                      //       results.data.length > 1 &&
                      //       results.data[0][0].toUpperCase() === 'DATE' &&
                      //       results.data[0][1].toUpperCase() === 'VALUE'
                      //     ) {
                      //       setDataBacktest(results.data);
                      //       basketSelect.dataBacktest = results.data
                      //       const updatedArray = listBasket.map(item =>
                      //         item.id === selectOptionPreview.value ? basketSelect: item
                      //       );
                      //       setDataArray(updatedArray);
                      //       setData(basketSelect);
                      //       toast(`Uploaded backtest is linked to ${basketSelect.symbol} Basket`)
                      //     } else {
                      //       toast.error(
                      //         'Invalid file type structure. Please upload a file that matches the required format',
                      //       );
                      //     }
                      //   },
                      //   header: false,
                      // });
                    }
                  }}
                  primary
                  height="35px"
                >
                  Upload File
                </BitaButton>
              </div>
              <p className={style.nameFile}>{filesBacktest && `(${filesBacktest?.name})`}</p>
            </div>
          </div>

          <div>{data?.dataBacktest?.length > 0 && <BacktestTable />}</div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BitaButton
          onClick={() => {
            processBaskets();
            // const updatedData = {
            //   ...data,
            //   type: type.includes('Bottom') ? 'global' : type.toLowerCase().replace('/', '_'),
            // }; // Add the 'type' property to the data object

            // delete updatedData.cash_distribution; // Remove the "cash_distribution" key
            // createBasket(token, updatedData)
            //   .then(rsp => {
            //     // setShowModal(true);
            //     // setData(rsp.data?.data);
            //   })
            //   .catch(error => {
            //     toastInterceptor(error);
            //   });
          }}
          disabled={dataArray.length === 0}
          primary
        >
          Apply
        </BitaButton>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '10px',
          }}
        >
          <BiSolidError className="icon-error" />
          <div>This option is not enabled for your assigned organization</div>
        </div> */}
      </div>
      {/* This modal is not being activated after apply */}
      <SaveSuccessBasketModal
        showModal={showModal}
        setShowModal={setShowModal}
        data={data}
        setData={setData}
      />

      {/* confirm upload backtest modal Modal */}
      <BitaModal
        isModalOpen={showModalConfirmBacktest}
        setModalState={() => setShowModalConfirmBacktest(false)}
      >
        <div style={{ width: '600px ', minHeight: '200px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Upload Backtest
            </h2>
            <span onClick={() => setShowModalConfirmBacktest(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              This basket already has backtest attached to it. Do you want to overwrite with new
              backtest?
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  Papa.parse(filesBacktest, {
                    complete: results => {
                      if (
                        results.data.length > 1 &&
                        results.data[0][0].toUpperCase() === 'DATE' &&
                        results.data[0][1].toUpperCase() === 'VALUE'
                      ) {
                        setDataBacktest(results.data);
                        const listBasket = [...dataArray];
                        const basketSelect = {
                          ...listBasket.find(item => item.symbol === selectOptionPreview.value),
                        };
                        handleFileParse(listBasket, basketSelect);
                        setShowModalConfirmBacktest(false);
                      } else {
                        toast.error(
                          'Invalid file type structure. Please upload a file that matches the required format',
                        );
                      }
                    },
                    header: false,
                  });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Yes
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalConfirmBacktest(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* loading basket Modal */}
      <BitaModal
        isModalOpen={showModalBasketInProgress}
        setModalState={() => setShowModalBasketInProgress(false)}
      >
        <div style={{ width: '600px ', minHeight: '200px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SUBMITTING BASKETS IN PROGRESS
            </h2>
            <span onClick={() => setShowModalBasketInProgress(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Submitting baskets is currently in progress. You can follow the progress below.
            </div>
            <div
              style={{
                border: '1px solid white',
                margin: '30px',
                padding: '10px',
                height: '200px',
              }}
            >
              <div>Baskets created:</div>
              {dataSendBasket.map(item => (
                <div style={{ display: 'flex' }}>
                  Ticker: {item.symbol}{' '}
                  {item.send === 'success' ? (
                    <BiCheck className="tooltip-icon icon-info" />
                  ) : (
                    <BiSolidError className="icon-error" />
                  )}
                </div>
              ))}
            </div>
            <div
              className="no__progress__value__indicator"
              style={{ width: '200px', margin: 'auto' }}
            >
              <Progress percent={99} className="builderProgressBar" />
            </div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Submitting {dataSendBasket.length}/{dataArray.length} Baskets ...
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => cleanState()}
                disabled={dataSendBasket.length !== dataArray.length}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </div>
  );
};

export default BulkBasketSubmission;
