import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import FileSaver from 'file-saver';
import { post, get } from 'axios';
import { useHistory } from 'react-router';
import Papa from 'papaparse';
import { BiSolidError, BiX, BiCheck } from 'react-icons/bi';
import { FaCheckSquare } from 'react-icons/fa';

import { uploadFileSettings, coverageRequest, updateBasket } from './utils/api';
import BitaInput from '../../components/BitaInput/BitaInput';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';
import { toastInterceptor } from '../../utils/utils';
import { URL_CONSTANTS } from '../../api-data-mapping/url-constants';
import './Portfolio.css';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import BitaSearchBar from '../../bitaComponents/BitaSearchBar/BitaSearchBar';
import BitaCalendarDate from '../../components/BitaCalendar/BitaCalendarDate';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';

import { isFloatWithMoreThanTwoDecimals } from './utils';

import style from './update-basket.module.scss';
import styles from './PortfolioBasketCalculation.module.scss';
import tableStyles from './table.module.scss';
import { DUMMIE_PREFIX } from './utils/dataDummie';

// const uploadFileSettings = (tokenUser, file) => {
//   return post(URL_CONSTANTS.UPLOADFILELIVEBASKET, file, {
//     headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${tokenUser}` },
//   });
// };

const getBasket = (tokenUser, data) => {
  return get(URL_CONSTANTS.GETLIVEBASKET, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const basketButton = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  Global: [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const dataDummieBacktest = [
  {
    timestamp: '2024-01-08',
    value: 346.33003479675256,
  },
  {
    timestamp: '2024-01-09',
    value: 344.97767292139974,
  },
  {
    timestamp: '2024-01-10',
    value: 347.02822361244307,
  },
  {
    timestamp: '2024-01-11',
    value: 346.33003479675256,
  },
  {
    timestamp: '2024-01-12',
    value: 344.97767292139974,
  },
  {
    timestamp: '2024-01-13',
    value: 347.02822361244307,
  },
];
const DummiePrefix = 'ABC';

const formatDateString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

// Verifies that a date is from today or later (and not a date in the past). For example: valid dates: today date, tomorrow date. Invalid dates: yesterday date, last month date, etc.
function isValidDateString(dateString) {
  // Check if the date string matches the format yyyy-mm-dd
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date string into a Date object
  const date = new Date(`${dateString}T00:00:00`); // Treat as local midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to local midnight

  // Check if the date is today or in the future
  return date >= today;
}

// TODO improve this with the isValidDateString
const isValidDateFormat = dateString => {
  let isValid = false;
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    isValid = false;
  }

  const date = new Date(dateString);
  isValid = date.toISOString().slice(0, 10) === dateString;

  return isValid;
};

const UpdateBasket = () => {
  const [files, setFiles] = React.useState('');
  const user = useSelector(state => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [showModalTicker, setShowModalTicker] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [currentView] = React.useState(null);
  const [type] = useState('Long only Basket');
  const [data, setData] = useState();
  const [dataBacktest, setDataBacktest] = useState([]);
  const [idIndex, setIdIndex] = useState();
  const [newTicker, setNewTicker] = useState('');
  const [selectBasket, setSelectBasket] = useState();
  const [filesBacktest, setFilesBacktest] = React.useState('');
  const [listBasket, setListBasket] = useState([]);
  const [preview, setPreview] = useState(newTicker);
  const [effectiveDate, setEffectiveDate] = useState(Date.now());
  const [constituentsDelete, setConstituentsDelete] = useState([]);
  const [showModalRequestCoverage, setShowModalRequestCoverage] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRequestCoverageSuccessful, setShowModalRequestCoverageSuccessful] = useState(
    false,
  );
  const [showSedolNotFoundModal, setShowSedolNotFoundModal] = useState(false);
  const [sedolsList, setSedolsList] = useState([]);
  const { token } = user;
  const [itHasLaunchDateError, setItHasLaunchDateError] = useState(false);

  const inputBasketRef = useRef(null);
  const inputBacktestRef = useRef(null);

  const history = useHistory();

  // Add a custom row to remove the U icon in the first column
  const CustomRow = ({ value }) => {
    return (
      <div>
        <p>{isFloatWithMoreThanTwoDecimals(value) || value}</p>
      </div>
    );
  };

  const getColumns = valueData => {
    const columns = Object.keys(valueData?.metadata ?? {}).filter(column => column !== 'exchange');
    columns.unshift('constituents');
    if (Object.keys(valueData?.metadata ?? {}).find(column => column === 'exchange')) {
      columns.unshift('MIC');
    }
    return columns;
  };

  const renderRows = valueData => {
    const constituents = Object.keys(valueData?.metadata?.awf ?? {});

    const rows = constituents.map(constituent => {
      const rowData = {
        constituents: constituent,
      };

      Object.keys(valueData?.metadata).forEach(key => {
        rowData[key] =
          key === 'divisor' ? valueData.metadata[key] : valueData.metadata[key][constituent];
      });
      if (rowData.exchange) {
        rowData.MIC = rowData.exchange;
      }
      return rowData;
    });

    return rows;
  };

  useEffect(() => {
    getBasket(token)
      .then(rsp => {
        setListBasket(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });
  }, []);

  const resetBasketData = () => {
    setData(null);
    setFiles('');
    setSelectBasket(null);
    setFilesBacktest('');
  };

  const handleApplyButton = () => {
    const formData = new FormData();
    const updateData = {
      basket_id: selectBasket.id,
      effective_date: formatDateString(effectiveDate),
      ...data,
    };

    if (filesBacktest) {
      formData.append('template', filesBacktest);
      formData.append('symbol', selectBasket.name);
    }

    if (files) {
      formData.append('data', JSON.stringify(updateData));
    }

    updateBasket(token, formData)
      .then(rsp => {
        setShowModalConfirm(false);
        toast('Your Basket has been processed successfully');

        setShowModal(true);
        setIdIndex(selectBasket?.id);
        // setIdIndex(rsp?.data?.data?.parent_index?.index_id);
        if (inputBacktestRef && inputBacktestRef.current) {
          inputBacktestRef.current.value = '';
        }

        // resetBasketData(); // Reset the state after successfully processed the basket
      })
      .catch(error => {
        toastInterceptor(error);
      });
  };

  const BacktestTable = () => {
    const renderTable = () => {
      const columns = Object.keys(dataBacktest?.[0]);
      const rows = dataBacktest.slice(1);

      return (
        <div style={{ height: '340px' }}>
          <div style={{ border: 'none', marginBottom: '0px' }} className={tableStyles.tableTitle}>
            Backtest Data Preview
          </div>
          <BitaTable
            data={rows}
            search={false}
            showFields={columns}
            height="300px"
            showOnly={10}
            pagination
            selectAction={() => {}}
            CustomRowComponent={({ value }) => (
              <div>
                <p style={{ paddingRight: '20px' }}>{value}</p>
              </div>
            )}
          />
        </div>
      );
    };
    return <div className={style.contentWidget}>{renderTable()}</div>;
  };

  const resetStates = () => {
    setNewTicker('');
    setPreview('');
  };

  const handleUpdateTicker = () => {
    setShowModalTicker(true);
    resetStates();
  };

  const handleApplyUpdateTicker = () => {
    setShowModalTicker(false);
    alert('not integrated');
  };

  // Allow that is just alphanumeric or an empty string (this because it needs to start writing the ticker from an empty string)
  const isAValidTicker = value => {
    return /^[A-Za-z0-9]+$/.test(value) || value === '';
  };

  const BasketTable = ({ titleBasketTable, deleteBasketTable, buttonsBasketTable, noScroll }) => {
    const renderTable = () => {
      if (type === 'Long only Basket' && data.metadata) {
        const dataRows = data?.meta_reference_data ?? data.metadata;
        const columns = Object.keys(dataRows).filter(column => column !== 'exchange');
        columns.unshift('constituents');
        if (Object.keys(dataRows).find(column => column === 'exchange')) {
          columns.unshift('MIC');
        }

        const rows = Object.keys(data.metadata.awf).map((constituent, index) => {
          const rowData = {
            constituents: constituent,
          };

          Object.keys(dataRows).forEach(key => {
            rowData[key] = key === 'divisor' ? dataRows[key] : dataRows[key][constituent];
          });
          if (rowData.exchange) {
            rowData.MIC = rowData.exchange;
          }

          // if (constituentsError.includes(constituent)) {

          if (data?.request_coverage?.some(item => constituent in item)) {
            rowData.info = 'error';
          } else {
            rowData.info = 'success';
          }
          return rowData;
        });

        return (
          <div className="contentTableBasketPreview">
            {titleBasketTable && (
              <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
            )}
            {titleBasketTable && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1>General file information section</h1>
              </div>
            )}
            <BitaTable
              data={rows}
              height="100%"
              style={{ padding: '0' }}
              search={false}
              scroll={!noScroll}
              showFields={deleteBasketTable ? [...columns, 'info'] : columns}
              selectAction={() => {}}
              editButton={false}
              CustomRowComponent={({ dataValue, entryKey, value }) => (
                <div className="custom-row-container">
                  <p className={`${entryKey === 'info' ? 'info-icon-live' : ''}`}>
                    {entryKey === 'info' && deleteBasketTable ? (
                      value === 'error' ? (
                        <BiSolidError className="icon-error" />
                      ) : (
                        <FaCheckSquare className="icon-info" />
                      )
                    ) : entryKey === 'constituents' && deleteBasketTable ? (
                      <div style={{ display: 'flex' }}>
                        <BitaCheckbox
                          checked={constituentsDelete.includes(value)}
                          handleChange={checked => {
                            if (checked) {
                              setConstituentsDelete([...constituentsDelete, value]);
                            } else {
                              setConstituentsDelete(
                                constituentsDelete.filter(item => item !== value),
                              );
                            }
                          }}
                        />
                        {value}
                      </div>
                    ) : (
                      value
                    )}
                  </p>
                  {entryKey === 'info' && value === 'error' && (
                    <div className="tooltip error-tooltip">
                      <div>
                        <BiX className="tooltip-icon icon-error" />
                      </div>
                      <span>
                        This instrument is not under the BITA Calculation platform pricing coverage.
                        You can request the coverage using the button below &quot; Request Coverage
                        &quot; . We will receive the request, process it, and communicate with you
                        via e-mail as soon as possible.
                      </span>
                    </div>
                  )}
                  {entryKey === 'info' && value === 'success' && (
                    <div className="tooltip success-tooltip">
                      <div>
                        <BiCheck className="tooltip-icon icon-info" />
                      </div>
                      <span>The input has been successfully validated</span>
                    </div>
                  )}
                </div>
              )}
            />

            {data.request_coverage && buttonsBasketTable && (
              <BitaButton
                disabled={!Object.keys(data.request_coverage).length > 0}
                primary
                width="160px"
                style={{ marginRight: 'auto', marginTop: '60px' }}
                height="35px"
                onClick={() => setShowModalRequestCoverage(true)}
              >
                Request Coverage
              </BitaButton>
            )}

            {buttonsBasketTable && (
              <BitaButton
                disabled={constituentsDelete.length === 0}
                primary
                width="160px"
                style={{ marginLeft: '20px', marginTop: '60px' }}
                height="35px"
                onClick={() => {
                  setShowModalDelete(true);
                }}
              >
                Delete Constituent
              </BitaButton>
            )}
          </div>
        );
      }

      // Hide this for now
      // if (type === 'Bottom-Up Basket' && data.children_symbols) {
      //   const rows = data.children_symbols.map(symbol => ({
      //     children_symbols: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>
      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['children_symbols']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      // Hide this for now
      // if (type === 'Long/Short' && data.global_baskets) {
      //   const rows = Object.values(data.global_baskets).map(symbol => ({
      //     basket_symbol: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>

      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['basket_symbol']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      if (type === 'Decrement Index') {
        return <></>;
      }
      return null;
    };

    return <div className={titleBasketTable ? '' : style.contentWidget}>{renderTable()}</div>;
  };

  return (
    <div>
      {/* Live Basket(s) selector and Basket Data Preview */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Live Basket(s)</div>
        <div className={styles.contentDataUpload}>
          <div style={{ marginRight: 12 }}>
            <div className={style.container}>
              <div style={{ width: '235px', paddingLeft: '5%', paddingBottom: '5%' }}>
                <div className={style.subtitle} style={{ paddingBottom: '10%' }}>
                  Basket
                </div>
                <BitaSearchBar
                  forceStopLoading
                  key="Baskets"
                  placeholder="Live Basket(s)"
                  searchOptions={listBasket?.map(item => {
                    return {
                      label: item?.symbol,
                      key: item?.id,
                      value: item?.symbol,
                      id: item?.id,
                      name: item?.symbol,
                      date: item,
                    };
                  })}
                  value={selectBasket?.value}
                  onChange={selected => setSelectBasket(selected)}
                />
              </div>
            </div>
          </div>

          {selectBasket?.value && (
            <div className={styles.contentUpdateBasket}>
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <div
                className={tableStyles.tableContainer}
                style={{ display: 'flex', width: '100%' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '28%' }}>
                  <div className={style.tableNoMetadata}>
                    <div style={{ display: 'flex' }}>
                      <div>Symbol: </div> <div>{selectBasket?.date?.symbol}</div>
                    </div>
                    {/* {selectBasket?.date?.name && (
                      <div style={{ display: 'flex' }}>
                        <div>Name: </div> <div>{selectBasket?.date?.name}</div>
                      </div>
                    )} */}
                    {/* {selectBasket?.date?.description && (
                      <div style={{ display: 'flex' }}>
                        <div>Description: </div> <div>{selectBasket?.date?.description}</div>
                      </div>
                    )} */}

                    <div style={{ display: 'flex' }}>
                      <div>open hour: </div> <div>{selectBasket?.date?.calculation_open_time}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>close hour: </div>{' '}
                      <div>{selectBasket?.date?.calculation_close_time}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>timezone: </div> <div>{selectBasket?.date?.timezone}</div>
                    </div>
                  </div>
                </div>

                <div style={{ width: '72%' }} className="tableUpdateBasket">
                  <BitaTable
                    data={renderRows(selectBasket?.date)}
                    search={false}
                    showFields={getColumns(selectBasket?.date)}
                    selectAction={() => {}}
                    CustomRowComponent={CustomRow}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Basket Composition/Portfolio */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Update Basket Composition/Portfolio</div>

        {/* Templates and Basket Data Preview */}
        <div className={styles.contentDataUpload}>
          <div>
            <div className={style.container}>
              <div className={style.contentTemplateUpload}>
                <div className={style.subtitle}>TEMPLATES</div>
                {Object.values(basketButton[type] ?? {}).map(button => (
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/${button?.url}.csv`,
                        `${button?.url}.csv`,
                      );
                    }}
                  >
                    {button?.label}
                  </BitaButton>
                ))}
              </div>

              <div className={style.dataUpload}>
                <div className={style.contentTemplate}>
                  <div className={style.subtitle}>PORTFOLIO UPLOADER</div>
                  <input
                    type="file"
                    onChange={event => {
                      setFiles(event.target.files[0]);
                    }}
                    ref={inputBasketRef}
                    name="file"
                    id="file"
                    className={style.inputfile}
                  />
                  <label htmlFor="file">Select file</label>

                  <BitaButton
                    disabled={!files}
                    onClick={() => {
                      const formData = new FormData();
                      formData.append('template', files);

                      if (type === 'Long only Basket') {
                        formData.append('type', 'basket');
                      } else {
                        formData.append('type', type.replace('/', '_'));
                        // formData.append(
                        //   'type',
                        //   type.includes('Bottom') ? 'global' : type.replace('/', '_'),
                        // );
                      }
                      setData({});
                      setDataBacktest([]);
                      setFilesBacktest('');
                      if (files) {
                        uploadFileSettings(token, formData)
                          .then(rsp => {
                            // Validate launch date
                            const launchDateString = rsp.data.data?.launch_date;
                            if (
                              launchDateString &&
                              (!isValidDateFormat(launchDateString) ||
                                !isValidDateString(launchDateString))
                            ) {
                              toast.warn(
                                'Warning: The effective date (launch_date) is invalid. By default, the date has been set as today. The accepted dates need to be greater or equal to today.',
                              );
                            }
                            // if (
                            //   launchDateString &&
                            //   isValidDateFormat(launchDateString) &&
                            //   isValidDateString(launchDateString)
                            // ) {
                            //   setItHasLaunchDateError(false); // There is no error
                            // } else {
                            //   setItHasLaunchDateError(true); // There is an error
                            //   toast.warn(
                            //     'Warning: The effective date (launch_date) is invalid. By default, the date has been set as today. The accepted dates need to be greater or equal to today.',
                            //   );
                            // }

                            inputBasketRef.current.value = '';

                            const sedolNotFoundArr = rsp.data.data?.sedols_not_found || null;
                            if (sedolNotFoundArr && sedolNotFoundArr.length > 0) {
                              setSedolsList(sedolNotFoundArr);
                              setShowSedolNotFoundModal(true);
                            }
                            if (rsp.data.data?.metadata?.divisor && rsp.data.data?.starting_value) {
                              // eslint-disable-next-line no-shadow
                              const { starting_value: _, divisor, ...updatedData } = rsp.data.data;
                              setData({ ...updatedData });
                              toast('Your file has been processed successfully');
                            } else if (
                              !rsp.data.data?.metadata?.divisor &&
                              !rsp.data.data?.starting_value
                            ) {
                              toast.error(
                                'Error: Invalid input file. Please make sure you have "divisor" or "starting_value" field and try again.',
                              );
                            } else {
                              // deleting unnecesaty divisor
                              const { divisor: _, ...upddData } = rsp.data.data;
                              setData({ ...upddData });
                              toast('Your file has been processed successfully');
                            }
                          })
                          .catch(error => {
                            // toast.error(
                            //   error?.response?.data?.error
                            //     ? error?.response?.data?.error
                            //     : 'Error: Invalid input file. Please make sure the file is in the correct format and try again.',
                            // );
                            toastInterceptor(error);
                          });
                      } else {
                        toast.error(
                          'Please upload a portfolio template with a different file name.',
                        );
                      }
                    }}
                    primary
                    width="160px"
                    height="35px"
                  >
                    Upload File
                  </BitaButton>
                </div>
                <p className={style.nameFile}>{files && `(${files.name})`}</p>
              </div>

              <div style={{ marginLeft: '4%', width: '30%' }}>
                <BitaCalendarDate
                  title="Effective Date"
                  select={value => setEffectiveDate(value)}
                  maxDate={new Date('3000-12-12')}
                  minDate={new Date()}
                  date={effectiveDate}
                />
              </div>
            </div>
          </div>

          {/* {data && (
            <div className="tableUpdateBasket">
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <BitaTable
                data={renderRows(data)}
                search={false}
                showFields={getColumns(data)}
                selectAction={() => {}}
                CustomRowComponent={CustomRow}
              />
            </div>
          )} */}

          <div style={{ padding: '0px 20px ' }}>
            {data?.metadata && (
              <div
                className="contentTableBasketPreview"
                style={{ marginTop: '0px', paddingTop: '0px' }}
              >
                <div className="headerTable">
                  <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>General file information section</h1>
                  </div>
                </div>
                {data && (
                  <div data-simplebar style={{ height: '360px' }}>
                    <div>
                      <BasketTable deleteBasketTable noScroll />
                    </div>
                  </div>
                )}
                {data && data.request_coverage && true && (
                  <BitaButton
                    disabled={!Object.keys(data.request_coverage).length > 0}
                    primary
                    width="160px"
                    style={{ marginRight: 'auto', marginTop: '20px' }}
                    height="35px"
                    onClick={() => setShowModalRequestCoverage(true)}
                  >
                    Request Coverage
                  </BitaButton>
                )}

                {data && true && (
                  <BitaButton
                    disabled={constituentsDelete.length === 0}
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Delete Constituent
                  </BitaButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Backtest section */}
      <div className={style.compositionPortfolio}>
        <div className={style.compositionPortfolioTitle}>Update Backtest</div>

        {/* Backtest Uploader */}
        <div className={styles.contentDataUpload} style={{ marginLeft: '18px' }}>
          <div className={style.dataUpload}>
            <div className={style.container}>
              <div>
                <div className={style.subtitle}>TEMPLATES</div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginTop: '10px',
                    marginBottom: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <BitaButton
                    primaryWhite
                    width="160px"
                    height="35px"
                    onClick={() => {
                      FileSaver.saveAs(
                        `${process.env.PUBLIC_URL}/files/backtest_template.csv`,
                        `backtest_template.csv`,
                      );
                    }}
                  >
                    Backtest Template
                  </BitaButton>
                </div>
              </div>
              <div className={style.subtitle}>Backtest Uploader</div>
              <div
                className={style.containerButtonsUpload}
                // style={{
                //   display: 'flex',
                //   alignItems: 'flex-end',
                //   flexWrap: 'wrap',
                //   // pointerEvents: selectBasket?.value && data ? 'auto' : 'none',
                //   // opacity: selectBasket?.value && data ? 1 : 0.5,
                // }}
              >
                {/* <div>
                  <CSVReader
                    onUploadAccepted={results => {
                      setDataBacktest(results?.data);
                    }}
                  >
                    {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                      <>
                        <div>
                          <button className={style.buttonUpload} type="button" {...getRootProps()}>
                            Select File
                          </button>
                          <div style={styles.acceptedFile} />
                        </div>
                        <div className={style.nameFile}>
                          {acceptedFile && `(${acceptedFile.name})`}
                          {acceptedFile && setFilesBacktest(acceptedFile)}
                        </div>
                      </>
                    )}
                  </CSVReader>
                </div> */}

                <div>
                  <input
                    className={style.buttonUpload}
                    type="file"
                    accept=".csv"
                    onChange={event => {
                      const file = event.target.files[0];
                      setFilesBacktest(file);
                    }}
                    style={{ display: 'none' }} // Oculta el elemento <input>
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className={style.buttonUpload}>
                    Select File
                  </label>
                  <div style={styles.acceptedFile} />
                </div>
                <BitaButton
                  disabled={!filesBacktest?.name}
                  width="160px"
                  onClick={() => {
                    Papa.parse(filesBacktest, {
                      complete: results => {
                        console.info(results);
                        if (
                          results.data.length > 1 &&
                          results.data[0][0].toUpperCase() === 'DATE' &&
                          results.data[0][1].toUpperCase() === 'VALUE'
                        ) {
                          setDataBacktest(results.data);
                        } else {
                          toast.error(
                            'Invalid file type structure. Please upload a file that matches the required format',
                          );
                        }
                      },
                      header: false,
                    });
                  }}
                  primary
                  height="35px"
                >
                  Upload File
                </BitaButton>
              </div>

              <p className={style.nameFile}>{filesBacktest && `(${filesBacktest?.name})`}</p>
            </div>
          </div>
          <div>{dataBacktest.length > 0 && <BacktestTable />}</div>
        </div>
      </div>

      {/* Apply Button */}
      <div className={style.applyButton}>
        <BitaButton
          onClick={() => setShowModalConfirm(true)}
          // disabled={currentView === 'Single Basket Submission' ? !data : !(data && selectBasket)}
          disabled={
            itHasLaunchDateError ||
            !(selectBasket && (data?.metadata || dataBacktest.length > 0)) ||
            !data?.full_coverage ||
            Object.keys(data?.request_coverage)?.length > 0 ||
            Object.keys(data?.metadata?.exchange)?.length <= 0
          }
          style={{ margin: 'auto' }}
          primary
        >
          Apply
        </BitaButton>
      </div>

      {/* Success Modal */}
      <BitaModal
        isModalOpen={showModal}
        onClick={() => {
          setShowModal(false);
          resetBasketData();
        }}
      >
        <div style={{ width: ' 600px ', minHeight: '300px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <span
              onClick={() => {
                setShowModal(false);
                resetBasketData();
              }}
              style={styleClose}
            >
              &times;
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={style.modalTextTitle}>Update successfully completed!</div>
            <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
              <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />
              <div>
                <p> Updated ID: {selectBasket?.id}</p>
                <p> Updated Name: {selectBasket?.date?.name}</p>
                <p> Updated Status: Transmitted to BITA ACE </p>
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}
          >
            <BitaButton primaryWhite onClick={() => history.push('/')} height="35px" width="200px">
              Go to Home
            </BitaButton>
            <BitaButton
              primaryWhite
              onClick={() => {
                setShowModal(false);
                resetBasketData();
              }}
              height="35px"
              width="200px"
            >
              Update another basket
            </BitaButton>
          </div>
        </div>
      </BitaModal>

      {/* Update Ticker Modal */}
      <BitaModal isModalOpen={showModalTicker} setModalState={() => setShowModalTicker(false)}>
        <div style={{ width: ' 600px ', minHeight: '300px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Update Ticker
            </h2>
            <span onClick={() => setShowModalTicker(false)} style={styleClose}>
              &times;
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '40px',
            }}
          >
            <div
              className={styles.contentInput}
              style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
            >
              <div style={{ pointerEvents: 'none' }}>
                <BitaInput
                  value={data?.prefix ?? DummiePrefix}
                  type="text"
                  inputStyle={styles.basketInput}
                  title="Org. Prefix"
                  disabled
                  externalTitle="Org. Prefix"
                  placeholder="Prefix"
                  handleChange={val => {
                    console.info('prefix');
                  }}
                />
              </div>
              <BitaInput
                value={newTicker}
                type="text"
                inputStyle={styles.basketInput}
                title="Ticker"
                maxlength={8 - (data?.prefix?.length ?? DummiePrefix?.length)}
                externalTitle="Ticker"
                placeholder="Ticker"
                handleChange={value => {
                  if (isAValidTicker(value)) {
                    setNewTicker(value.toUpperCase());
                    setPreview(DummiePrefix + value.toUpperCase());
                  }
                }}
              />
            </div>

            <div
              className={styles.contentInput}
              style={{ display: 'flex', gap: '12px', justifyContent: 'center', marginTop: '20px' }}
            >
              <div style={{ pointerEvents: 'none' }}>
                <BitaInput
                  disabled
                  value={preview}
                  type="text"
                  inputStyle={styles.basketInput}
                  title="Preview"
                  externalTitle="Preview"
                  placeholder="Ticker"
                  handleChange={() => {}}
                />
              </div>
            </div>
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}
          >
            <BitaButton
              primaryWhite
              onClick={handleApplyUpdateTicker}
              disabled={!(newTicker?.length > 0)}
              height="35px"
              width="200px"
            >
              Apply
            </BitaButton>
          </div>
        </div>
      </BitaModal>
      {/* Confirm Modal */}
      <BitaModal isModalOpen={showModalConfirm} setModalState={() => setShowModalConfirm(false)}>
        <div
          style={{
            width: '850px',
            minHeight: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              UPDATE BASKET
            </h2>
            <span onClick={() => setShowModalConfirm(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px 0', textAlign: 'center' }}>
              Are you sure you want to update these parameters?
            </div>

            <div data-simplebar style={{ height: '460px' }}>
              <div className={tableStyles.tableTitle}>Basket Data Preview</div>
              <div
                className={tableStyles.tableContainer}
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <div style={{ display: 'flex' }}>
                      <div>Symbol: </div> <div>{selectBasket?.date?.symbol}</div>
                    </div>
                    {/* {selectBasket?.date?.name && (
                      <div style={{ display: 'flex' }}>
                        <div>Name: </div> <div>{selectBasket?.date?.name}</div>
                      </div>
                    )}
                    {selectBasket?.date?.description && (
                      <div style={{ display: 'flex' }}>
                        <div>Description: </div> <div>{selectBasket?.date?.description}</div>
                      </div>
                    )} */}

                    <div style={{ display: 'flex' }}>
                      <div>open hour: </div> <div>{selectBasket?.date?.calculation_open_time}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>close hour: </div>{' '}
                      <div>{selectBasket?.date?.calculation_close_time}</div>
                    </div>
                    {/* <div style={{ display: 'flex' }}>
                      <div>timezone: </div> <div>{selectBasket?.date?.timezone}</div>
                    </div> */}

                    <div style={{ display: 'flex' }}>
                      <div>EFFECTIVE DATE: </div> <div>{formatDateString(effectiveDate)}</div>
                    </div>
                  </div>
                </div>

                <div style={{ width: '100%' }}>
                  {data && (
                    <div className="tableUpdateBasket">
                      <BitaTable
                        data={renderRows(data)}
                        search={false}
                        showFields={getColumns(data)}
                        selectAction={() => {}}
                        CustomRowComponent={CustomRow}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div>{dataBacktest.length > 0 && <BacktestTable />}</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={handleApplyButton}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalConfirm(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* SEDOL(S) NOT FOUND Modal */}
      <BitaModal
        isModalOpen={showSedolNotFoundModal}
        setModalState={() => setShowSedolNotFoundModal(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SEDOL(S) NOT FOUND
            </h2>
            <span onClick={() => setShowSedolNotFoundModal(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Sorry, no information was found for these Sedol(s). Please check your file or contact
              support.
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Sedol(s)</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    {sedolsList.map(item => (
                      <div className={style.requestCoverageEntry}>
                        <div>{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowSedolNotFoundModal(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Accept
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* showModalRequestCoverage Modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverage}
        setModalState={() => setShowModalRequestCoverage(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverage(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              We will send to the organization&apos;s e-mail the request for the following
              constituents
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <div className={style.requestCoverageEntry}>
                      <div>ISIN</div>
                      <div>MIC</div>
                    </div>
                    {(data?.request_coverage ?? []).map(item => (
                      <div className={style.requestCoverageEntry}>
                        <div>{Object.keys(item)[0]}</div>
                        <div>{Object.values(item)[0]}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  const body = {
                    user_name: user.username,
                    company_name: user.company,
                    requets_coverage: data?.request_coverage,
                  };
                  setShowModalRequestCoverage(false);
                  coverageRequest(token, body)
                    .then(rsp => {
                      if (rsp.data.data.email_sent) {
                        setShowModalRequestCoverageSuccessful(true);
                      } else {
                        toast.error('We had a problem processing your request');
                      }
                      setShowModalRequestCoverage(false);
                    })
                    .catch(error => {
                      toast.error('We had a problem processing your request');
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalRequestCoverage(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* delete constituents modal Modal */}
      <BitaModal isModalOpen={showModalDelete} setModalState={() => setShowModalDelete(false)}>
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Delete Constituents
            </h2>
            <span onClick={() => setShowModalDelete(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Do you want to remove these constituents?
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    {constituentsDelete.map(item => (
                      <div>{item}</div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  setShowModalDelete(false);
                  const deleteArray = (arr, elementos) => {
                    return arr.filter(item => !elementos.includes(item));
                  };

                  const updateData = () => {
                    setData(prevData => {
                      const newData = { ...prevData };
                      const newMetadata = { ...newData?.metadata };
                      const newCustomPrice = { ...newData?.custom_prices?.prices };
                      Object.keys(newMetadata).forEach(key => {
                        if (Array.isArray(newMetadata[key])) {
                          newMetadata[key] = deleteArray(newMetadata[key], constituentsDelete);
                        } else {
                          newMetadata[key] = { ...newMetadata[key] };
                          Object.keys(newMetadata[key]).forEach(subKey => {
                            if (constituentsDelete.includes(subKey)) {
                              delete newMetadata[key][subKey];
                              if (data.metadata.exchange[subKey] === 'CASH') {
                                delete newData.cash_constituent;
                              }
                            }
                          });
                        }
                      });
                      if (Object.keys(newCustomPrice).length > 0) {
                        Object.keys(newCustomPrice).forEach(subKey => {
                          if (constituentsDelete.includes(subKey)) {
                            delete newCustomPrice[subKey];
                            if (data.metadata.exchange[subKey] === 'CASH') {
                              delete newData.cash_constituent;
                            }
                          }
                        });

                        newData.custom_prices.prices = newCustomPrice;
                      }
                      newMetadata.divisor = newData.divisor;
                      newData.metadata = newMetadata;
                      const newRequestCoverage = newData?.request_coverage.filter(item => {
                        return !Object.keys(item)?.some(key => constituentsDelete.includes(key));
                      });

                      newData.request_coverage = newRequestCoverage;
                      return newData;
                    });
                  };
                  updateData();
                  setConstituentsDelete([]);
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Delete
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalDelete(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* sucessful coverage request modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverageSuccessful}
        setModalState={() => setShowModalRequestCoverageSuccessful(false)}
      >
        <div style={{ width: '600px ', minHeight: '130px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverageSuccessful(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Your request is being processed, we will contact you shortly.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowModalRequestCoverageSuccessful(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </div>
  );
};

export default UpdateBasket;
