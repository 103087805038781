/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { uniqueId, set } from 'lodash';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useCurrentModule } from '../../bitaHooks';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSearchBar from '../../bitaComponents/BitaSearchBar/BitaSearchBar';
import BitaStepper from '../../bitaComponents/BitaStepper/BitaStepper';
import Switcher from '../../bitaComponents/Switcher/Switcher';
import BitaCard from '../../components/BitaCard/BitaCard';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';
import BitaInput from '../../components/BitaInput/BitaInput';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaSlider from '../../bitaComponents/BitaRangeSlider/BitaSlider';
import PageLayout from '../../components/Layout/PageLayout';
import { setApiData } from '../../state/actions/api-data-actions';
import ConfigYAML from '../../config/ConfigYAML';
import * as CommonActions from '../../constants/common-constants';
import { Icons } from '../../constants/icons';
import styles from './optimized-index.module.scss';
import './optimization.css';
import RunBacktestModal from '../runBacktestModal';
import {
  setParentIndex,
  setParentIndexId,
  getMethodologyById,
  getUniverseById,
  setParentIndexStartDate,
} from '../../state/actions/backtest-actions';
import { remove_option } from '../../utils/utils';
import { optimizedOnlySingledatafieldCard } from '../../constants/user-company-access';

const formatApiData = (apiData, key) => {
  const res = _.get(apiData, `${key}.data`, [])
    .filter(i => i.index_type !== 'structured_index')
    .map(row => ({
      label: (
        <div className={`optionSearch ${row.components_count >= 2000 && 'optionSearchDisabled'}`}>
          {' '}
          <div>{row.name}</div>
          <div>( {row.components_count} )</div>
        </div>
      ),
      value: row.name,
      id: row.id,
      isDisabled: row.components_count >= 2000,
    }));
  return res;
};

const getCardSteps = (card, steps = []) => {
  if (card.step_number) {
    steps.push(card);
  }
  if (card.onclick && card.onclick.step_number) {
    return getCardSteps(card.onclick, steps);
  }
  if (card.onnext && card.onnext.step_number) {
    return getCardSteps(card.onnext, steps);
  }

  return steps;
};

const Selector = ({ setPath, paths, nextStep, componentSteps = [] }) => {
  useEffect(() => {
    setPath(paths[0]);
    nextStep();
  }, [setPath, nextStep, paths]);

  useEffect(() => {
    if (componentSteps.length) {
      nextStep();
    }
  }, [componentSteps, nextStep]);

  return <></>;
};

// const flexDisplay = { display: 'flex' };

const OptimizedIndex = withRouter(({ getFlow }) => {
  const modalRef = useRef();
  const [componentSteps, setComponentSteps] = useState([]);
  const planModules = useSelector(state => state.auth.plan.modules);
  const [userPlan, setUserPlan] = useState({});
  const [componentStepsPlan, setComponentsStepsPlan] = useState([]);
  const nameTabActive = useSelector(state => state.common.subheader).split('• ');
  const dispatch = useDispatch();
  const apiData = useSelector(state => state.apiData.apiData);

  useEffect(() => {
    const planList = {};
    if (planModules) {
      planModules.forEach(module => {
        planList[module.name] = module;
      });
      setUserPlan(planList);
    }
  }, [planModules]);

  useEffect(() => {
    if (userPlan['OPTIMIZED INDEX BUILDER']) {
      userPlan['OPTIMIZED INDEX BUILDER'].sub_modules.forEach(sub_module => {
        if (
          sub_module.name === nameTabActive[nameTabActive.length - 1] &&
          sub_module.functionalities
        ) {
          setComponentsStepsPlan(sub_module.functionalities);
        }
      });
    }
  }, [userPlan]);
  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE} | Optimized Index Builder`;
  }, []);

  React.useEffect(() => {
    dispatch({ type: CommonActions.SUBHEADER_UPDATE, payload: 'OPTIMIZED INDEX BUILDER' });
  }, [dispatch]);

  const steps = useMemo(() => {
    let pathSteps;

    const [flow] = getFlow
      ? ConfigYAML.jsonPathOptimizer('$.structured_products.sections[?(@.type=="Flow")]')
      : ConfigYAML.jsonPathOptimizer('$.optimizer.sections[?(@.type=="Flow")]');
    if (!componentSteps || !componentSteps.length) {
      pathSteps = Object.values(flow.default_step_names).map(name => ({
        name,
      }));
      pathSteps[0] = { ...pathSteps[0], ...flow.steps[0] };
    } else {
      // Filtra con el plan componentStepsPlan para agregar el status a los steper
      componentSteps.forEach(step => {
        // eslint-disable-next-line
        const auxplan = componentStepsPlan.filter(auxStep => {
          // eslint-disable-next-line no-unused-expressions
          // eslint-disable-next-line no-cond-assign

          return auxStep.name === step.name.toUpperCase();
        });
        step.status_plan = auxplan.length > 0 ? auxplan[0].status_plan : 'ACTIVE';
      });
      // Agregamos solo los que esten activos
      pathSteps = componentSteps.filter(auxStep => {
        // eslint-disable-next-line no-unused-expressions
        return auxStep.status_plan === 'ACTIVE';
      });
    }
    return {
      pathSelector: props => <Selector {...props} componentSteps={componentSteps} />,
      paths: [
        pathSteps.map(step => ({
          name: step.name,
          status_plan: step.status_plan ? step.status_plan : 'ACTIVE',
          component: props => (
            <OptimizationStep
              {...props}
              data={step}
              structuredIndex={getFlow === 'structured'}
              lastStep={pathSteps.length}
              setSteps={ssteps => {
                if (ssteps && ssteps.length) {
                  setComponentSteps([flow.steps[0], ...ssteps]);
                } else {
                  setComponentSteps([]);
                }
              }}
            />
          ),
        })),
      ],
      type: 'buttonSteps',
    };
  }, [componentSteps, componentStepsPlan]);

  const getBenchmark = () => {
    const getDataFor = ['Benchmark', 'Indexes'];
    let instrumentList = [];
    // eslint-disable-next-line array-callback-return
    getDataFor.map(dataName => {
      const list = _.get(apiData, dataName, {});
      if (list.data) {
        let dataList = list.data;
        if (dataName === 'Benchmark') {
          dataList = list.data.Benchmark;
        }
        instrumentList = instrumentList.concat(
          dataList.map(row => {
            return {
              label: `${row.name} - ${
                row.index_type
                  ? row.index_type
                      .replace('regular', 'INDEX')
                      .replace('structured_index', 'STRUCTURED PRODUCT')
                      .replace('optimized_index', 'OPTIMIZED PRODUCT')
                  : 'ETF'
              }`,
              value: row.name,
              id: row.id,
              'Instrument type': row.index_type
                ? row.index_type
                    .replace('regular', 'INDEX')
                    .replace('structured_index', 'STRUCTURED PRODUCT')
                    .replace('optimized_index', 'OPTIMIZED PRODUCT')
                : 'ETF',
            };
          }),
        );
      }
    });
    const parentIndex = _.get(
      apiData,
      ['Optimized Index', '2_0_Parent Index', 'parent_index', 'id'],
      null,
    );
    return instrumentList.filter(i => i.id === parentIndex)[0];
  };
  const getRiskModel = () => {
    const riskModelList = _.get(apiData, ['risk_models', 'data', 'Risk Model Bita'], []);
    const parentIndex = _.get(
      apiData,
      ['Optimized Index', '2_0_Parent Index', 'parent_index', 'id'],
      null,
    );
    const indexList = _.get(apiData, ['Indexes', 'data'], []);
    const parentRisk = indexList.filter(i => i.id === parentIndex);
    if (parentRisk.length > 0) {
      const risk = riskModelList.filter(r => r.id === parentRisk[0].risk_model_id);
      if (risk.length > 0) {
        return risk[0];
      }
    }
    return '';
  };
  return (
    <PageLayout>
      <RunBacktestModal ref={modalRef} showData ubication="optimized" disable_title={false} />
      <div className={styles.pageContainer}>
        {/* TODO recordar que aqui se coloca el evento */}
        <BitaStepper
          key={componentSteps.map(({ name }) => name).join('')}
          // crntStep={componentSteps.length > 0 ? 1 : 0}
          data={steps}
          submit={() => {
            dispatch(
              setApiData({
                title: 'indexBacktest',
                data: {
                  'Index Backtest': {
                    'BASE VALUE': 1000,
                    Benchmark: getBenchmark(),
                    risk_model_id: getRiskModel(),
                  },
                },
              }),
            );
            modalRef.current.runModal();
          }}
        />
      </div>
    </PageLayout>
  );
});

const OptimizationStep = props => {
  const [stepData, setStepData] = React.useState({});
  const { setSteps = Function.prototype } = props;
  const [secondStepValidated, setSecondStepValidated] = React.useState(false);
  const [factorSelectionValidated, setFactorSelectionValidated] = React.useState(false);
  const [datafieldSelectionValidated, setDataFieldSelectionValidated] = React.useState(false);
  const toastActive = new Set();
  const isStructuredIndex = props.structuredIndex;
  const dispatch = useDispatch();
  const apiState = useSelector(state => state.apiData.apiData);
  const optimizedIndex = useSelector(state => state.apiData.apiData['Optimized Index']);
  const token = useSelector(state => state?.auth?.token);

  useEffect(() => {
    if (optimizedIndex && Object.values(optimizedIndex).length > 0) {
      setStepData(optimizedIndex);
    }
  }, []);
  useEffect(() => {
    if (Object.values(stepData).length > 0) {
      dispatch(
        setApiData({
          title: 'Optimized Index',
          data: { ...stepData },
        }),
      );
    }
  }, [stepData]);

  const showInfoToast = text => {
    if (toastActive.size < 1) {
      const id = toast(text, {
        // I'm using the onClose hook here to remove the id
        // from the set
        onClose: () => toastActive.delete(id),
      });
      toastActive.add(id);
    }
  };

  // validaciones
  useEffect(() => {
    switch (props.data.name) {
      case 'Parent Index':
        if (_.has(stepData, ['2_0_Parent Index', 'parent_index'])) {
          setSecondStepValidated(true);
        }
        break;
      case 'Datafield Selection':
        // eslint-disable-next-line no-case-declarations
        let selectionKeys = [];
        if (stepData[`3_1_${props.data.title}`] !== undefined) {
          selectionKeys = Object.keys(stepData[`3_1_${props.data.title}`])?.filter(key =>
            key.match(/score*?(\d+)/),
          );
        }
        if (selectionKeys.length > 1) {
          const stepPrevData = stepData;
          delete stepPrevData[`3_1_${props.data.title}`][selectionKeys[0]];
          selectionKeys.shift();
          setStepData(stepPrevData);
        }
        if (
          _.has(stepData, [`3_0_${props.data.title}`]) &&
          (_.has(stepData, [`3_1_${props.data.title}`, ['Weight by']]) ||
            selectionKeys.length === 1)
        ) {
          setDataFieldSelectionValidated(true);
        }

        break;
      case 'Factor Selection':
        if (
          _.has(stepData, [`4_0_${props.data.title}`]) &&
          _.has(stepData, [`4_1_${props.data.title}`, 'factor_exposure']) &&
          _.has(stepData, [`4_1_${props.data.title}`, 'factors']) &&
          _.get(stepData, [`4_1_${props.data.title}`, 'factor_exposure']) === 'FACTOR EXPOSURE'
        ) {
          const factors = _.get(stepData, [`4_1_${props.data.title}`, 'factors']);
          if (
            factors.length > 0 &&
            factors[0].factor &&
            (factors[0].weight || factors.length === 1)
          ) {
            let status = true;
            let sum = 0;
            if (factors.length > 1) {
              for (let i = 0; factors.length > i; i += 1) {
                if (!(factors[i].factor && factors[i].weight)) {
                  status = false;
                }
              }
              factors.forEach(f => {
                sum += parseFloat(f.weight || 0);
              });
              if (sum > 100 && status) {
                status = false;
              } else if (Math.abs(sum - 100) >= 0.01 && status) {
                status = false;
              }
            }
            setFactorSelectionValidated(status);
          } else {
            setFactorSelectionValidated(false);
          }
        }
        if (
          _.has(stepData, [`4_0_${props.data.title}`]) &&
          _.has(stepData, [`4_2_${props.data.title}`])
        ) {
          const type = _.get(stepData, [`4_2_${props.data.title}`, 'weights', 'value']);
          const factors = _.get(stepData, [`4_2_${props.data.title}`, 'factors']);
          if (type === 'Individual Factors' && factors.length > 0) {
            let validate = true;
            factors.forEach(item => {
              if (!(item.factor.id && item.cap && item.floor)) {
                validate = false;
              }
            });
            setFactorSelectionValidated(validate);
          } else if (
            type === 'All Factors' &&
            _.has(stepData, [`4_2_${props.data.title}`, 'cap'])
          ) {
            setFactorSelectionValidated(true);
          } else {
            setFactorSelectionValidated(false);
          }
        }
        break;
      default:
        break;
    }
  }, [optimizedIndex]);

  const getMethodology = id => {
    dispatch(getMethodologyById(token, id));
  };

  const getUniverse = id => {
    dispatch(getUniverseById(token, id));
  };

  const getIndexData = () => {
    const indice = Object.entries(optimizedIndex).find(([step, value]) =>
      step.endsWith('Parent Index'),
    );
    if (indice) {
      const indexId = indice[1].parent_index.id;
      const indexList = _.get(apiState, 'Indexes', {});
      if (indexList.data) {
        const selectedIndex = indexList.data.find(ind => ind.id === indexId);
        if (selectedIndex) {
          dispatch(setParentIndexId(selectedIndex.id));
          dispatch(setParentIndex(selectedIndex.name));
          dispatch(setParentIndexStartDate(selectedIndex.start_date));
          getMethodology(selectedIndex.methodology_id);
          getUniverse(selectedIndex.universe_id);
        }
      }
    }
  };

  const disableValidations = () => {
    setSecondStepValidated(false);
    setFactorSelectionValidated(false);
    setDataFieldSelectionValidated(false);
  };

  const enableNextStep = () => {
    if (!isStructuredIndex) {
      switch (props.data.name) {
        case 'Parent Index':
          return secondStepValidated;
        case 'Factor Selection':
          return factorSelectionValidated;
        case 'Datafield Selection':
          return datafieldSelectionValidated;
        default:
          return true;
      }
    }
    return true;
  };

  const mouseEnter = () => {
    if (!enableNextStep())
      switch (props.data.name) {
        case 'Parent Index':
          showInfoToast('Please select a Parent Index.');
          break;
        case 'Factor Selection':
          if (
            _.has(stepData, [`4_0_${props.data.title}`]) &&
            _.has(stepData, [`4_1_${props.data.title}`, 'factor_exposure']) &&
            _.has(stepData, [`4_1_${props.data.title}`, 'factors'])
          ) {
            const factors = _.get(stepData, [`4_1_${props.data.title}`, 'factors'], {});
            if (factors.length > 1) {
              for (let i = 0; factors.length > i; i += 1) {
                if (!(factors[i].factor && factors[i].weight)) {
                  showInfoToast('All Factors Exposure must have a weight.');
                }
              }
              let sum = 0;
              factors.forEach(f => {
                sum += parseFloat(f.weight || 0);
              });
              if (sum > 100) {
                toast('Factors Exposure distributed weight cannot be higher than 100%.', {
                  toastId: 'distributed_toast',
                });
              } else if (Math.abs(sum - 100) >= 0.01) {
                toast('Factors Exposure distributed weight must be closest to 100%', {
                  toastId: 'validate_values',
                });
              }
            }
            break;
          }
          showInfoToast('Please, select which factor you want to optimize.');
          break;
        default:
          break;
      }
  };
  const runbacktest = () => {
    getIndexData();
    props.submitStepper();
  };

  return (
    <div className={styles.optimizationStep}>
      <div className={styles.optimizationStepHeader}>
        <div>
          <h1 className={styles.stepTitle}>
            <Icons.account /> <span>{props.data.title} </span>
          </h1>
          <p className={styles.stepSubtitle}>{props.data.text}</p>
        </div>
        <div className={styles.stepNavigation}>
          <BitaButton
            className={classnames(styles.stepNavigationButton, {
              [styles.stepNavigationButtonVisible]: props.data.step_number > 1,
            })}
            onClick={() => {
              props.previousStep();
              if (props.data.step_number === 2) {
                setSteps([]);
              }
              const deleteStep = stepData;
              Object.entries(stepData).forEach(step => {
                if (step[0].startsWith(`${props.data.step_number}`)) {
                  delete deleteStep[step[0]];
                }
              });
              setStepData(deleteStep);
              disableValidations();
            }}
            primary
          >
            Previous
          </BitaButton>
          <div onMouseEnter={mouseEnter} onFocus={() => {}}>
            <BitaButton
              disabled={!enableNextStep()}
              className={classnames(styles.stepNavigationButton, {
                [styles.stepNavigationButtonVisible]: props.data.step_number > 1,
              })}
              onClick={() => {
                props.data.step_number !== props.lastStep ? props.nextStep() : runbacktest();
              }}
              primary
            >
              {props.data.step_number !== props.lastStep ? 'Next' : 'Run Backtest'}
            </BitaButton>
          </div>
        </div>
      </div>
      {props.data.components && props.data.step_number !== 1 && (
        <div style={{ padding: 20 }}>
          {Object.values(props.data.components).map((component, index) => (
            <OptimizationComponent
              setSteps={setSteps}
              key={`${props.data.step_number}_${index}_${props.data.title}`}
              {...props}
              {...component}
              index={`${props.data.step_number}_${index}_${props.data.title}`}
              setStepData={setStepData}
              stepData={stepData}
              kind="component"
            />
          ))}
        </div>
      )}
      {props.data.step_number === 1 && (
        <OptimizationComponent
          setSteps={setSteps}
          key={props.data.title}
          {...props}
          index={`${props.data.step_number}_CARDLIST_${props.data.title}`}
          setStepData={setStepData}
          stepData={stepData}
          kind="cards"
        />
      )}
      <div style={{ padding: 20 }}>
        {props.data.widgets &&
          Object.values(props.data.widgets).map((widget, index) => (
            <OptimizationComponent
              setSteps={setSteps}
              setStepData={setStepData}
              stepData={stepData}
              key={widget.title || widget.id}
              index={`${props.data.step_number}_${index}_${props.data.title}`}
              {...props}
              {...widget}
              kind="widget"
            />
          ))}
        {props.data.step_number === props.lastStep && (
          <div className={styles.centerRunBacktest}>
            <BitaButton
              className={classnames(styles.stepNavigationButton, {
                [styles.stepNavigationButtonVisible]: props.data.step_number > 1,
              })}
              onClick={() => {
                runbacktest();
              }}
              primary
            >
              Run Backtest
            </BitaButton>
          </div>
        )}
      </div>
    </div>
  );
};

const OptimizationComponent = props => {
  const apiData = useSelector(state => state.apiData.apiData);
  const searchOptions = formatApiData(apiData, 'Indexes');
  if (props.kind === 'cards') {
    return (
      <div className={styles.cardOptimizationComponentContainer}>
        {Object.values(props.data.components)
          .filter(card => remove_option(!card.disabled))
          .map(component => (
            <OptimizationCardComponent {...component} {...props} />
          ))}
      </div>
    );
  }
  if (props.type === 'SearchBar') {
    return <OptimizationSearchComponent searchOptions={searchOptions} {...props} />;
  }
  if (props.type === 'Input') {
    if (props.hideComponent) return <div />;
    return <OptimizationInputComponent {...props} />;
  }
  if (props.type === 'Toggle') {
    // eslint-disable-next-line no-use-before-define
    let Component = OptimizationSwitchComponent;

    if (props.kind === 'widget') {
      // eslint-disable-next-line no-use-before-define
      Component = OptimizationWidgetSwitchComponent;
    }
    if (props.options) {
      return <OptimizationSwitchHolder {...props} Component={Component} options={props.options} />;
      // return Object.values(props.options).map(option => <Component {...props} {...option} />);
    }
    return <Component {...props} />;
  }
  if (props.type === 'Dropdown' && (props.options || props.data_fields)) {
    return <OptimizationDropdownComponent {...props} />;
  }
  if (props.type === 'AddibleRows') {
    return <OptimizationAddibleRowsComponent {...props} />;
  }
  if (props.type === 'Card') return <OptimizationCardComponent {...props} />;

  if (props.type === 'CheckBox') {
    return <OptimizationCheckboxComponent {...props} />;
  }

  if (props.type === 'RatioButtons') {
    return <OptimizationRatioButtonsComponent {...props} />;
  }
  if (props.type === 'SortableList') {
    return <OptimizationSortableList {...props} />;
  }
  return (
    <>
      <span>Unknown element &quot;{props.type}&quot;</span>
    </>
  );
};

const OptimizationSearchComponent = props => {
  const { searchOptions } = props;
  const onChange = selected => {
    const constituents =
      props.id === 'parent_index' ? selected?.label?.props?.children[2]?.props?.children[1] : null;
    props.setStepData((prev = {}) => ({
      ...prev,
      [props.index]: {
        ...prev[props.index],
        [props.id]: { name: selected.value, id: selected.id, constituents },
      },
    }));
  };
  return (
    <div style={{ width: '50%' }}>
      <BitaSearchBar searchOptions={searchOptions} onChange={onChange} />
    </div>
  );
};
const OptimizationCardComponent = props => {
  const user = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { setSteps = Function.prototype } = props;
  const { historyPlan = {} } = props;
  let status = props.disabled ? 'INACTIVE' : 'ACTIVE';
  const ubication = useCurrentModule()[0].toUpperCase();
  const userPlan = {};
  // eslint-disable-next-line array-callback-return
  useSelector(state => state.auth.plan.modules).map(module => {
    userPlan[module.name] = module;
  });

  if (!historyPlan.module) {
    historyPlan.module = ubication;
    if (historyPlan.module === 'STRUCTURED INDEX') {
      historyPlan.module = 'STRUCTURED INDEX BUILDER';
    }
    if (historyPlan.module === 'OPTIMIZED INDEX') {
      historyPlan.module = 'OPTIMIZED INDEX BUILDER';
    }

    if (
      userPlan[historyPlan.module].functionalities &&
      userPlan[historyPlan.module].functionalities.length > 0
    ) {
      userPlan[historyPlan.module].functionalities.forEach(funcionalitie => {
        if (funcionalitie.name.toUpperCase() === props.title.toUpperCase())
          status = funcionalitie.status_plan;
      });
    }
    if (
      userPlan[historyPlan.module].sub_modules &&
      userPlan[historyPlan.module].sub_modules.length > 0
    ) {
      userPlan[historyPlan.module].sub_modules.forEach(sub_module => {
        if (sub_module.name.toUpperCase() === props.title.toUpperCase())
          status = sub_module.status_plan;
      });
    }
  }
  if (
    (!optimizedOnlySingledatafieldCard[user.userdata.id_company] ||
      optimizedOnlySingledatafieldCard[user.userdata.id_company].find(
        item => item === props?.title,
      )) &&
    (status === 'ACTIVE' || status === 'INACTIVE')
  ) {
    return (
      <BitaCard
        className={styles.optimizationCard}
        title={props.title.toUpperCase()}
        // text={props.description}
        cardType={BitaCard.FILTER}
        icon={Icons.account}
        styles={{
          opacity: status === 'ACTIVE' && !props.disabled ? '1' : '0.5',
          pointerEvents: status === 'ACTIVE' && !props.disabled ? 'auto' : 'none',
        }}
        onClick={() => {
          if (props.onclick) {
            setSteps(getCardSteps(props.onclick));
            dispatch({
              type: CommonActions.SUBHEADER_UPDATE,
              payload: `OPTIMIZED INDEX BUILDER • ${props.title.toUpperCase()}`,
            });
            dispatch(
              setApiData({
                title: 'Optimized Index',
                data: { [props.index]: { [props.id]: props.title } },
              }),
            );
            props.setStepData({
              [props.index]: { [props.id]: props.title },
            });
            props.nextStep();
          }
        }}
      />
    );
  }
  return <div />;
};

const OptimizationRatioButtonsComponent = props => {
  const [selected, setSelected] = useState(props.selected || '');
  const [components, setComponents] = useState([]);
  const [uniqueName] = useState(uniqueId('radio-filter-'));
  const data = useMemo(() => {
    if (props.onclick_option) {
      const storeOptions = Object.values(props.options).map(option => ({
        name: option.value,
        onclick: option.onclick,
      }));
      return storeOptions;
    }
    const storeOptions = Object.values(props.options).map(option => ({
      name: option,
    }));
    return storeOptions;

    // eslint-disable-next-line
  }, [props.options]);
  return (
    <div
      className={`${styles.builderRatioButtonsContainer} builderRatioButtonsContainer`}
      style={{ paddingBottom: '10px', width: props.width ? props.width : '' }}
    >
      <div className={`${styles.builderRatioButtonsOptions} builderRatioButtonsOptions`}>
        <b className={styles.optimizationSwitchComponentName} style={{ paddingRight: '30px' }}>
          {props?.text?.toUpperCase()}
        </b>
        {Object.values(data).map(option => (
          <>
            <input
              id={`radio-${uniqueName}-${option.name}`}
              type="radio"
              name={uniqueName}
              value={option.name}
              onChange={() => {
                setSelected(option.name);
                props.setStepData &&
                  props.setStepData((prev = {}) => ({
                    ...prev,
                    [`${props.index}`]: option.name,
                  }));
                if (props.onChange) {
                  props.onChange(option.name);
                }
                if (option.onclick) {
                  setComponents(option.onclick);
                } else {
                  setComponents([]);
                }
              }}
              checked={selected === option.name}
            />
            <label htmlFor={`radio-${uniqueName}-${option.name}`}>{option.name}</label>
          </>
        ))}
      </div>
      <div>
        {components.length > 0 &&
          Object.values(components).map(comp => <OptimizationComponent {...comp} />)}
      </div>
    </div>
  );
};

const OptimizationSwitchHolder = ({ Component, ...props }) => {
  const [switchOnIndex, setSwitchOnIndex] = useState(
    Object.values(props.options).findIndex(option => option.toggled_by_default),
  );
  const resetSwitches = () => {
    setSwitchOnIndex(null);
  };

  React.useEffect(() => {
    if (props.widgetData && props.widgetData[props.id]) {
      if (typeof props.options[0] === 'object') {
        const dataSelected = Object.values(props.options).findIndex(
          option => option.name === props.widgetData[props.id],
        );
        setSwitchOnIndex(dataSelected);
      } else {
        setSwitchOnIndex(props.widgetData[props.id]);
      }
    }
  }, []);

  return Object.values(props.options).map((option, index) => (
    <Component
      key={`${index}_${option.name}`}
      {...props}
      title={null}
      name={option.name}
      groupSwitch={{
        switchOn: index === switchOnIndex,
        resetSwitches,
        setSwitchOnIndex,
        index,
      }}
      components={null}
      {...option}
    />
  ));
};

const OptimizationSwitchComponent = props => {
  const [checked, setChecked] = useState(
    (props.groupSwitch && props.groupSwitch.switchOn) || false,
  );

  useEffect(() => {
    if (props.groupSwitch && props.groupSwitch.switchOn) {
      props.setStepData((prev = {}) => ({
        ...prev,
        [`${props.index}`]: { ...prev[props.index], [`${props.id}`]: props.title || props.name },
      }));
    }
  }, []);

  useEffect(() => {
    if (props.groupSwitch) setChecked(props.groupSwitch.switchOn);
  }, [props.groupSwitch]);

  const name = useMemo(() => props.name || props.title, [props.title, props.name]);

  const toggle = useCallback(() => {
    setChecked(!checked);
    if (props.groupSwitch) {
      props.groupSwitch.setSwitchOnIndex(props.groupSwitch.index);
    }
    props.setStepData((prev = {}) => ({
      ...prev,
      [`${props.index}`]: !checked
        ? { [`${props.id}`]: props.title || props.name }
        : { ...prev[props.index] },
    }));
  }, [checked, setChecked, props]);

  return (
    <div
      className="optimizationSwitchComponent"
      style={{
        opacity: props.disabled ? '0.5' : '1',
        pointerEvents: props.disabled ? 'none' : '',
      }}
    >
      <div
        className={styles.optimizationSwitchComponentTop}
        style={{ width: props.sizetitle ? '360px' : ' ' }}
      >
        {name && <b className={styles.optimizationSwitchComponentName}>{name}</b>}
        {props.labeloff && <b>{props.labeloff}</b>}
        <span className={styles.optimizationSwitchComponentHolder}>
          <Switcher.Switch onChange={toggle} checked={checked} />
        </span>
        {props.labelon && <b>{props.labelon}</b>}
      </div>
      <div>
        {props.onclick &&
          checked &&
          Object.values(props.onclick).map(comp => (
            <div
              key={comp.id}
              className={`${styles.optimizationSwitchContent} ${
                props.subcomponents_direction === 'horizontal'
                  ? styles.optimizationSwitchContentHorizontal
                  : styles.optimizationSwitchContentVertical
              } `}
            >
              <OptimizationComponent {...props} {...comp} />
            </div>
          ))}
      </div>
    </div>
  );
};

const OptimizationWidgetSwitchComponent = props => {
  // eslint-disable-next-line
  const { stepData, setStepData } = props;
  const [applyDisabled, setapplyDisabled] = useState(false);
  const apiState = useSelector(state => state.apiData.apiData);

  const [deleteDisabled, setdeleteDisabled] = useState(!applyDisabled);
  const [widgetData, setWidgetData] = useState(_.get(apiState, 'Optimized Index', {}));
  /** es aqui */
  const [checked, setChecked] = useState(props.toggled_by_default || false);

  useEffect(() => {
    const optimizedIndex = _.get(apiState, 'Optimized Index', {});
    if ((optimizedIndex && widgetData[props.index]) || props.title === 'Negligible weight') {
      if (props.title === 'Negligible weight') {
        optimizedIndex[`${props.lastStep}_0_Extra Rules (optional)`] = {
          negligible_weight: '0.02',
        };
      }
      setWidgetData(optimizedIndex);
      setChecked(true);
      setapplyDisabled(true);
      setdeleteDisabled(false);
    }
    if (optimizedIndex && !widgetData[props.index] && props.title === 'Diversification Target') {
      if (props.title === 'Diversification Target') {
        const constituents = _.get(
          optimizedIndex,
          ['2_0_Parent Index', 'parent_index', 'constituents'],
          1,
        );
        optimizedIndex[props.index] = {
          diversification_target: 'Diversification Target',
          max: (500 / constituents).toFixed(4),
        };
        setWidgetData(optimizedIndex);
        setChecked(true);
        setapplyDisabled(true);
        setdeleteDisabled(false);
        setStepData(optimizedIndex);
      }
    }
    if (props.required) {
      setapplyDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (props.required || props.opcionalrequired || props.title === 'Factor') {
      setapplyDisabled(true);
      if (_.has(widgetData, [props.index, props.required])) {
        setapplyDisabled(false);
        setdeleteDisabled(true);
      }
      if (props.opcionalrequired) {
        if (
          _.get(widgetData, [props.index])?.return === 'SAME AS PARENT' ||
          _.get(widgetData, [props.index])?.volatility === 'SAME AS PARENT'
        ) {
          setapplyDisabled(false);
          setdeleteDisabled(true);
        } else {
          Object.values(props.opcionalrequired).map(item => {
            if (_.has(widgetData, [props.index, item])) {
              setapplyDisabled(false);
              setdeleteDisabled(true);
              return 0;
            }
          });
        }
      }
      if (
        _.has(widgetData, [props.index, 'factor']) &&
        _.get(widgetData, [props.index])?.weights?.value === 'All Factors' &&
        _.get(widgetData, [props.index])?.floor &&
        _.get(widgetData, [props.index])?.cap
      ) {
        setapplyDisabled(false);
        setdeleteDisabled(true);
      }
      if (
        _.has(widgetData, [props.index, 'factor']) &&
        _.get(widgetData, [props.index])?.weights?.value === 'Individual Factors' &&
        _.get(widgetData, [props.index])?.factors?.length > 0
      ) {
        const { factors } = _.get(widgetData, [props.index]);
        let validate = true;
        factors.forEach(item => {
          if (!(item?.factor?.id && item?.cap && item?.floor)) {
            validate = false;
          }
        });
        if (validate) {
          setapplyDisabled(false);
          setdeleteDisabled(true);
        }
      }
    }
  }, [widgetData]);

  const handleApply = () => {
    setapplyDisabled(true);
    setdeleteDisabled(false);
    setStepData(prev => ({
      ...prev,
      [props.index]: widgetData[props.index],
    }));
  };
  const toggle = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  const handleDelete = () => {
    setapplyDisabled(false);
    setdeleteDisabled(true);
    const aux = JSON.parse(JSON.stringify(stepData));
    delete aux[props.index];
    setStepData(aux);
    setWidgetData({});
    toggle();
  };

  return (
    <>
      <div
        className={`${styles.optimizationSwitchComponentContainer} ${props.title}`}
        style={{
          opacity: props.disabled ? '0.5' : '1',
          pointerEvents: props.disabled ? 'none' : '',
        }}
      >
        <div className={styles.optimizationWidgetHeaderContainer}>
          <span className={styles.optimizationWidgetTitle}>{props.name || props.title}</span>
          {props.labeloff && <b>{props.labeloff}</b>}
          <span style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
            <Switcher.Switch onChange={toggle} checked={checked} />
          </span>
          <span className={styles.widgetDescription}>{props.text}</span>
        </div>
        {props.labelon && <b>{props.labelon}</b>}
        {checked && props.components && (
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {Object.values(props.components).map(comp => (
              <div
                key={comp.id}
                className="subcomponente"
                style={{
                  display: 'block',
                  width: props.subcomponents_direction !== 'horizontal' ? '100%' : 'auto',
                  opacity: deleteDisabled === false ? '0.5' : '1',
                  pointerEvents: deleteDisabled === false ? 'none' : 'all',
                  marginBottom: '10px',
                }}
              >
                <OptimizationComponent
                  {...props}
                  stepData={widgetData}
                  setStepData={setWidgetData}
                  widgetData={widgetData[props.index]}
                  {...comp}
                  kind="component"
                />
              </div>
            ))}
          </div>
        )}
        {props.onclick &&
          checked &&
          Object.values(props.onclick).map(comp => <OptimizationComponent {...props} {...comp} />)}
        {checked && (
          <div className={styles.applyDeleteContainer}>
            <BitaButton
              className={styles.applyDeleteButton}
              width="90px"
              height="30px"
              primary
              onClick={handleApply}
              disabled={applyDisabled}
            >
              Apply
            </BitaButton>
            <BitaButton
              className={styles.applyDeleteButton}
              width="90px"
              height="30px"
              primary
              onClick={handleDelete}
              disabled={deleteDisabled}
            >
              Delete
            </BitaButton>
          </div>
        )}
      </div>
    </>
  );
};

const OptimizationCheckboxComponent = props => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    props.setStepData((prev = {}) => ({
      ...prev,
      [`${props.index}`]: { ...prev[props.index], [`${props.id}`]: checked },
    }));
  }, [checked]);
  return (
    <div className="buildercheckbox">
      <BitaCheckbox label={props.title} checked={checked} handleChange={setChecked} />
    </div>
  );
};

const OptimizationInputComponent = props => {
  const apiData = useSelector(state => state.apiData);
  // eslint-disable-next-line
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(_.get(props.stepData, `${props.index}.${props.id}`));
  }, [props.stepData]);
  const onChange = useCallback(
    val => {
      props.setStepData((prev = {}) => ({
        ...prev,
        [`${props.index}`]: { ...prev[props.index], [`${props.id}`]: val },
      }));
      setValue(val);
    },
    [setValue, props],
  );

  useEffect(() => {
    if (props.widgetData && props.widgetData[props.id]) {
      onChange(props.widgetData[props.id]);
    }
  }, []);

  useEffect(() => {
    if (props.widgetData && props.widgetData[props.id]) setValue(props.widgetData[props.id]);
  }, [props.widgetData]);

  const lower_limit =
    props.lower_limit === 'mod_constituents'
      ? (
          100 /
          _.get(
            apiData,
            ['apiData', 'Optimized Index', '2_0_Parent Index', 'parent_index', 'constituents'],
            0.1,
          )
        ).toFixed(2)
      : Number(props.lower_limit);

  return (
    <div className={styles.optimizationInputComponentContainer}>
      <div className={`${styles.optimizationInputContainer} optimizationInputContainer`}>
        <BitaInput
          id={props.id}
          title={props.titleinput}
          externalTitle={props.external_title}
          externalTitleStyle={styles.optimizationInputTitle}
          inputStyle={styles.widgetInput}
          labelinput={props.labelinput}
          placeholder={props.placeholder}
          value={value}
          justify="flex-start"
          handleChange={onChange}
          type={props.typeinput ? props.typeinput : 'text'}
          postText={props.post_text}
          upper_limit={props.upper_limit}
          lower_limit={lower_limit}
          tooltip={
            props.tooltip ||
            (props.upper_limit &&
              lower_limit &&
              `Please indicate a value between ${lower_limit} and 100`)
          }
        />
      </div>
    </div>
  );
};

const OptimizationDropdownComponent = props => {
  const {
    options,
    data_fields,
    data_fields_type,
    stepData,
    setStepData,
    subcomponents_direction = 'vertical',
  } = props;
  const [selected, setSelected] = useState();
  const [components, setComponents] = useState([]);
  const apiData = useSelector(state => state.apiData.apiData);
  useEffect(() => {
    setSelected(_.get(stepData, `${props.index}.${props.id}`));
  }, [stepData]);
  const data = useMemo(() => {
    const storeFields = {
      ..._.get(apiData, ['data_fields', 'data'], {}),
      ..._.get(apiData, ['Available Data Fields', 'data'], {}),
      ..._.get(apiData, ['client_data_fields', 'data'], {}),
      ..._.get(apiData, ['risk_models', 'data'], {}),
      ..._.get(apiData, ['risk_models_filter', 'data'], {}),
      ..._.get(apiData, ['risk_models_autor', 'data'], {}),
      ..._.get(apiData, ['Benchmark', 'data'], {}),
    };

    if (data_fields && storeFields) {
      const storeOptions =
        (Array.isArray(storeFields[data_fields]) &&
          storeFields[data_fields]
            .map((field, index) => {
              if (index === props.default_option_selected_position) {
                setSelected({ index, value: field.name });
                setStepData(prev => ({
                  ...prev,
                  [props.index]: {
                    ...prev[props.index],
                    [props.id]: { id: field.id, value: field.name },
                  },
                }));
              }
              return {
                id: field.id,
                value: field.name,
              };
            })
            .sort((a, b) => {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              return 0;
            })) ||
        [];
      if (storeOptions.length === 0) {
        if (data_fields === 'Bita ESG' && data_fields_type) {
          return storeFields[data_fields][data_fields_type]
            .map(field => ({
              id: field.id,
              value: field.name,
            }))
            .sort((a, b) => {
              if (a.value < b.value) {
                return -1;
              }
              if (a.value > b.value) {
                return 1;
              }
              return 0;
            });
        }
      }
      return storeOptions;
    }
    return Object.entries(options).map(([id, value]) => {
      if (id === props.default_option_selected_position) {
        setSelected({ id, value });
        setStepData(prev => ({
          ...prev,
          [props.index]: {
            ...prev[props.index],
            [props.id]: { id, value },
          },
        }));
      }
      if (typeof value === 'string') {
        return { id, value };
      }
      return { id, value: value.value };
    });

    // eslint-disable-next-line
  }, [options]);

  const onChangeS = useCallback(
    formatted => {
      if (stepData[props.index] && formatted) {
        setSelected(formatted);

        if (props.onChange) {
          props.onChange(formatted);
        }
        setStepData(prev => ({
          ...prev,
          [props.index]: {
            ...prev[props.index],
            [props.id]: formatted,
          },
        }));
      } else {
        setSelected(formatted);

        if (props.onChange) {
          props.onChange(formatted?.value);
        }
        setStepData((prev = {}) => ({
          ...prev,
          [`${props.index}`]: { ...prev[props.index], [`${props.id}`]: formatted },
        }));
      }
      props.options &&
        Object.entries(props.options).forEach(([key, value]) => {
          if (typeof value === 'string') {
            return;
          }
          if (value.value === formatted.value) {
            setComponents(Object.values(value.onclick));
          }
        });
      setSelected(formatted);
    },
    [setComponents, props, setStepData, stepData],
  );

  useEffect(() => {
    if (props.widgetData && props.widgetData[props.id]) {
      if (typeof props.widgetData[props.id] === 'object') {
        onChangeS(props.widgetData[props.id]);
      } else {
        const seldata = data.filter(row => row.value === props.widgetData[props.id])[0];
        if (typeof seldata !== 'undefined') {
          onChangeS(seldata);
        }
      }
    }
  }, []);

  const someAddible = useMemo(() => components.some(c => c.type === 'AddibleRows'), [components]);

  const selectComponentStyles = useMemo(() => {
    if (someAddible || subcomponents_direction === 'horizontal') {
      return { marginTop: components.length ? '12px' : 0, alignItems: 'center' };
    }
    return { float: 'left', marginTop: components.length ? '12px' : '12px', alignItems: 'center' };
  }, [someAddible, components.length, subcomponents_direction]);

  const subComponentsStyles = useMemo(() => {
    if (someAddible) {
      return { paddingTop: '6px' };
    }
    return {
      float: 'left',
      paddingLeft: '6px',
      display: 'flex',
      alignItems: 'flex-end',
    };
  }, [someAddible]);
  return (
    <div style={{ width: subcomponents_direction === 'vertical' ? '100%' : 'auto' }}>
      {props.external_title && <div className="externalTitle">{props.external_title}</div>}
      <div style={selectComponentStyles} className="selectComponent">
        {props.pre_text && (
          <span className={styles.optimizationDropdownPretext}>{props.pre_text}</span>
        )}
        <BitaSelect
          clearable={false}
          label={props.title}
          data={data}
          value={selected}
          handleSelection={onChangeS}
          selectClassname={styles.optimizedSelect}
          selected={props.default_option_selected_position}
          width={props.title && props.title.length > 28 ? '260px' : '200px'}
        />
      </div>
      <div style={subComponentsStyles}>
        {components.map(comp => (
          <OptimizationComponent key={comp.id} {...props} {...comp} />
        ))}
      </div>
    </div>
  );
};

const OptimizationAddibleRowsComponent = props => {
  const [addibleData, setAddibleData] = useState({});
  const [optionSelect, setoptionSelect] = useState(false);
  const apiData = useSelector(state => state.apiData.apiData);
  const [subComponents, setSubcomponents] = useState([Object.values(props.subcomponents)]);
  const [disableAdd, setdisableAdd] = useState(false);
  const addNew = useCallback(() => {
    const componentRows = [];
    let lengthOption = 0;
    Object.values(props.subcomponents).map(comp => {
      if (comp.type === 'Dropdown') {
        lengthOption = Object.values(comp.options).length;
        const optionsDropdown = Object.values(comp.options);
        Object.values(optionSelect).map(singleOption => {
          const pos = optionsDropdown.indexOf(singleOption);
          optionsDropdown.splice(pos, 1);
        });
        const dropdownNewOptions = {
          type: 'Dropdown',
          options: optionsDropdown,
          title: comp.title,
          id: comp.id,
        };
        componentRows.push(dropdownNewOptions);
      } else {
        componentRows.push(comp);
      }
    });
    setSubcomponents([...subComponents, componentRows]);
    if (lengthOption - 1 === optionSelect.length) {
      setdisableAdd(true);
    } else {
      setdisableAdd(false);
    }
  }, [subComponents, setSubcomponents, optionSelect, props.subcomponents]);

  const remove = useCallback(
    index => {
      setAddibleData(Object.values(addibleData).filter((_a, ind) => ind !== index));
      setSubcomponents(subComponents.filter((key, i) => i !== index));
      if (_.has(addibleData, `${index}.factor`))
        setoptionSelect(
          optionSelect.filter(val => val !== _.get(addibleData, `${index}.factor.value`, '')),
        );
      setdisableAdd(false);
    },
    [setSubcomponents, subComponents, props, addibleData, optionSelect],
  );

  const onChangeSelect = selectoption => {
    if (optionSelect) {
      setoptionSelect([...optionSelect, selectoption]);
    } else {
      setoptionSelect([selectoption]);
    }
  };
  useEffect(() => {
    props.setStepData(prev => ({
      ...prev,
      [props.index]: {
        ...prev[props.index],
        [props.id]: Object.values(addibleData),
      },
    }));
  }, [addibleData]);

  const dropdownOptions = [
    'Momentum',
    'Value',
    'Size',
    'Quality',
    'Low Volatility',
    'Liquidity',
    'Yield',
  ];
  useEffect(() => {
    if (props.widgetData && props.widgetData[props.id]) {
      const subcomp = [];
      props.widgetData[props.id].forEach(row => {
        const componentRows = [];
        const dropdownNewOptions = {
          type: 'Dropdown',
          options: dropdownOptions,
          id: 'factor',
          widgetData: props.widgetData[props.id],
          default_option_selected_position: row.factor.id,
        };
        const firstInput = {
          type: 'Input',
          typeinput: 'number',
          id: 'floor',
          external_title: 'Floor',
          title: 'Enter Value',
          placeholder: 'Enter %',
          widgetData: row,
        };
        const secondInput = {
          type: 'Input',
          typeinput: 'number',
          id: 'cap',
          external_title: 'Cap',
          title: 'Enter Value',
          placeholder: 'Enter %',
          widgetData: row,
        };
        componentRows.push(dropdownNewOptions);
        componentRows.push(firstInput);
        componentRows.push(secondInput);
        subcomp.push(componentRows);
      });
      setSubcomponents(subcomp);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {subComponents.map((group, index) => (
        <div key={index} className={`${styles.addibleRow} addibleRow `}>
          {group.map(comp => (
            <div key={comp.id} style={{ paddingRight: '6px' }}>
              <OptimizationComponent
                {...props}
                {...comp}
                index={index}
                setStepData={setAddibleData}
                stepData={addibleData}
                title={comp.title}
                onChange={onChangeSelect}
                hideComponent={props.disable_first_input && subComponents.length < 2}
              />
            </div>
          ))}
          <BitaButton className={styles.addibleRowRemoveButton} onClick={() => remove(index)}>
            &times;
          </BitaButton>
        </div>
      ))}
      <BitaButton className={styles.addibleRowPlusButton} onClick={addNew} disabled={disableAdd}>
        +
      </BitaButton>
    </>
  );
};

const OptimizationContrainsPriorizationRow = props => {
  const [soft, setSoft] = useState(props.soft);
  const [removable, setRemovable] = useState(props.removable);
  const [penalization, setPenalization] = useState(props.penalization);
  const refHover = useRef(null);

  const handleChange = () => {
    const updatedRow = {
      constraint: props.constraint,
      soft,
      removable,
      penalization,
    };
    props.onItemChange(updatedRow, props.constraintIndex);
  };

  useEffect(() => {
    handleChange();
    // eslint-disable-next-line
  }, [soft, removable, penalization]);

  const onMouseEnterName = () => {
    props.handleDrag(false);
  };

  const onMouseLeaveName = () => {
    props.handleDrag(true);
  };

  useEffect(() => {
    const node = refHover.current;
    if (node) {
      node.addEventListener('mouseover', onMouseEnterName);
      node.addEventListener('mouseout', onMouseLeaveName);

      return () => {
        node.removeEventListener('mouseover', onMouseEnterName);
        node.removeEventListener('mouseout', onMouseLeaveName);
      };
    }
    return {};
    // eslint-disable-next-line
  }, [refHover.current]);

  const softStyle = props.constraintIndex === 0 ? { marginTop: -1 } : {};
  return (
    <div className={styles.constraintPriorizationRow}>
      <span ref={refHover} className={styles.sortable}>
        || {props.constraint}
      </span>
      <BitaCheckbox
        label="Removable Constraint"
        handleChange={setRemovable}
        checked={props.removable}
        className={styles.invertedCheckBox}
      />
      <BitaCheckbox
        label="Soft Constraint"
        handleChange={setSoft}
        checked={props.soft}
        style={softStyle}
      />

      {soft && (
        <OptimizationRatioButtonsComponent
          width="300px"
          selected={`${penalization}`}
          options={['1', '2', '3', '4', '5']}
          onChange={val => setPenalization(val)}
        />
      )}
    </div>
  );
};

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);
const SortableConstraint = sortableElement(({ item, position, updateItem, handleDrag }) => (
  <OptimizationContrainsPriorizationRow
    {...item}
    constraintIndex={position}
    onItemChange={updateItem}
    handleDrag={handleDrag}
  />
));

const OptimizationSortableList = props => {
  const [sortableList, setSortableList] = useState([]);
  const onSortEnd = ({ oldIndex, newIndex }) =>
    setSortableList(arrayMove(sortableList, oldIndex, newIndex));
  const [disabledDrag, setDrag] = useState(true);
  const updateItem = (item, index) => {
    const updatedList = sortableList.slice();
    updatedList[index] = item;
    setSortableList(updatedList);
  };

  const constraintName = constraint => {
    if (_.has(constraint, 'security')) {
      return 'security cap - floor';
    }
    if (_.has(constraint, 'country')) {
      return 'country cap - floor';
    }
    if (_.has(constraint, 'factor')) {
      return 'factor cap - floor';
    }
    if (_.has(constraint, 'sector')) {
      return 'sector cap - floor';
    }
    if (_.has(constraint, 'turnover')) {
      return 'turnover';
    }
    if (_.has(constraint, 'maximum_drawdown')) {
      return 'maximum drawdown';
    }
    if (_.has(constraint, 'tracking_error')) {
      return 'tracking error';
    }
    if (_.has(constraint, 'diversification_target')) {
      return 'diversification target';
    }
    if (_.has(constraint, 'return')) {
      if (_.has(constraint, 'cap') || _.has(constraint, 'floor')) {
        return 'return cap - floor';
      }
      return 'return';
    }
    if (_.has(constraint, 'volatility')) {
      if (_.has(constraint, 'cap') || _.has(constraint, 'floor')) {
        return 'volatility cap - floor';
      }
      return 'volatility';
    }
    return false;
  };

  useEffect(() => {
    const list = [];
    if (sortableList.length === 0 && Object.keys(props.stepData).length > 0) {
      const objectEntries = Object.entries(props.stepData);
      const loadPreviousList = objectEntries.find(step =>
        step[0].toUpperCase().endsWith('PRIORITIZATION (OPTIONAL)'),
      );
      if (loadPreviousList) {
        setSortableList(loadPreviousList[1][props.id]);
      } else {
        objectEntries.map(constraint => {
          const stepName = constraint[0].toUpperCase();
          if (stepName.endsWith('CONSTRAINTS (OPTIONAL)')) {
            const itemList = {
              constraint: constraintName(constraint[1]),
              removable: constraintName(constraint[1]) === 'diversification target',
              soft: false,
              penalization: 3,
              disabledDrag: true,
            };
            list.push(itemList);
          }
        });
        setSortableList(list);
      }
    }
  }, []);

  useEffect(() => {
    props.setStepData(prev => ({
      ...prev,
      [props.index]: {
        ...prev[props.index],
        [props.id]: sortableList,
      },
    }));
  }, [sortableList]);

  if (sortableList.length > 0) {
    return (
      <div>
        {sortableList.filter(item => item.soft === true).length > 0 && (
          <div className={styles.constraintPriorizationRow}>
            <span className={styles.penalizationTitle}>penalization</span>
          </div>
        )}
        <SortableContainer axis="y" onSortEnd={onSortEnd} pressDelay={300} lockAxis="y">
          <React.Fragment>
            <ul>
              {sortableList.map((item, index) => (
                <SortableConstraint
                  disabled={disabledDrag}
                  key={`${index}-${item.constraint}-${item.removable}-${item.soft}-${item.penalization}`}
                  item={item}
                  index={index}
                  position={index}
                  updateItem={updateItem}
                  handleDrag={setDrag}
                />
              ))}
            </ul>
          </React.Fragment>
        </SortableContainer>
      </div>
    );
  }
  return <div />;
};

export default OptimizedIndex;
